import { useSelector } from "react-redux";

import FmdGoodIcon from "@mui/icons-material/FmdGood";

import TripsColumns from "../components/Trips/TripsColumns";

import { getTrips, getTripsWithFilter } from "../store/actions/trips";

import { setFrom, setTo } from "../store/slices/trips";

export default function TripsRoute() {
  const { isLoading, data } = useSelector((state) => state?.trips);

  return {
    path: "trips",
    pageName: "bookedCars",
    apiCall: getTrips,
    apiCallWithFilter: getTripsWithFilter,
    isLoading,
    IconPath: FmdGoodIcon,
    data,
    tableColumns: TripsColumns,
    setFrom,
    setTo,
  };
}
