// import { useTranslation } from "react-i18next";
// import { IconButton, Tooltip } from "@mui/material";

// import AddIcon from "@mui/icons-material/Add";

import { ButtonBase } from "@mui/material";
import DatePickerComponent from "../DatePicker/DatePicker";
import "./index.scss";

export default function CustomToolbar({
  apiCallWithFilter,

  setFrom,
  setTo,
  withoutFilter,
}) {
  // const { t } = useTranslation();

  return (
    <>
      {/* <Tooltip title={t("common.add")} onClick={handleOpenAddModal}>
        <IconButton>
          <AddIcon />
        </IconButton>
      </Tooltip> */}
      {!withoutFilter && (
        <DatePickerComponent
          apiCallWithFilter={apiCallWithFilter}
          setFrom={setFrom}
          setTo={setTo}
        />
      )}
    </>
  );
}
