import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import "./index.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function CommissionModal({
  open,
  handleClose,
  apiCallForTransfer,
  trackId,
}) {
  const { isLoading, isLoadingForAction } = useSelector(
    (state) => state?.sales
  );
  let navigate = useNavigate();
  const { t } = useTranslation();
  let dispatch = useDispatch();
  let [buttonDisabled, setButtonDisabled] = useState(false);

  const handleDelete = async () => {
    let res = await dispatch(apiCallForTransfer({ navigate, trackId }));
    setButtonDisabled(true);
    if (!res?.error) {
      handleClose();
    }
  };

  console.log("isLoadingForAction", isLoadingForAction);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="deleteDialog"
    >
      <DialogTitle
        sx={{ display: "flex", alignItems: "center", gap: "4px" }}
        id="alert-dialog-title"
      >
        <CurrencyExchangeIcon /> {t("modals.transfer")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("modals.transferItem")}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: "16px" }}>
        <button
          onClick={handleDelete}
          autoFocus
          type="submit"
          disabled={isLoadingForAction || isLoading}
        >
          {t("modals.transfer")}
        </button>
        <button onClick={handleClose}>{t("modals.cancel")}</button>
      </DialogActions>
    </Dialog>
  );
}
