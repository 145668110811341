import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import ModalImage from "react-modal-image";

import "./index.scss";
import { useTranslation } from "react-i18next";

const PaymentModal = ({
  PaymentType = "",
  amount = "",
  billNumber = "",
  sadadNumber = "",
  open,
  handleClose,
}) => {
  let { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center", maxWidth: "inherit" }}
        maxWidth="md"
      >
        <div className="modelCard">
          <DialogTitle id="alert-dialog-title">
            <h3 className="mb-5 fw-bold">{t("common.PaymentInfo")}</h3>
          </DialogTitle>
          <DialogContent>
            <div>
              <p className="mt-2 d-flex ">
                <span className="fw-bold  mx-2">{t("common.PaymentType")}</span>
                : {PaymentType}
              </p>
              <p className="mt-2 d-flex">
                <span className="fw-bold  mx-2">{t("common.amount")}</span>:{" "}
                {amount}
              </p>
              <p className="mt-2 d-flex">
                <span className="fw-bold  mx-2">{t("common.billNumber")}</span>:{" "}
                {billNumber}
              </p>
              <p className="mt-2 d-flex">
                <span className="fw-bold  mx-2">{t("common.sadadNumber")}</span>
                : {sadadNumber}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="closeButton" onClick={handleClose} autoFocus>
              {t("common.close")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default PaymentModal;
