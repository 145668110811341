import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CircularProgress } from "@mui/material";

import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";

import "./index.scss";

export default function Settings({
  infoDetails,
  loader,
  handleOpenChangePasswordModal,
}) {
  const { t } = useTranslation();

  return (
    <>
      {/* <iframe
        src={`https://docs.google.com/gview?${infoDetails?.contract?.en}=http://remote.${infoDetails?.contract?.en}.tld/path/to/document.doc&embedded=true`}
      ></iframe> */}
      <h2 className="mainTitleForPage">{t("common.settings")}</h2>
      <Card className="settingsCard">
        <CardContent>
          <section>
            <div>
              <InsertDriveFileOutlinedIcon />
              <p className="m-0 p-0">{t("profile.contract")}</p>
            </div>
            <div>
              {infoDetails?.contract ? (
                <a
                  href={
                    infoDetails !== null
                      ? localStorage.getItem("lang") === "ar" ||
                        sessionStorage.getItem("lang") === "ar"
                        ? `${infoDetails?.contract?.ar}`
                        : `${infoDetails?.contract?.en}`
                      : ""
                  }
                  target="_blank"
                >
                  <ArrowRightOutlinedIcon />
                </a>
              ) : (
                t("common.noContract")
              )}
            </div>
          </section>
          <section>
            <div>
              <CakeOutlinedIcon />
              <p className="m-0 p-0">{t("profile.birthdate")}</p>
            </div>
            <div>
              {infoDetails !== null ? (
                infoDetails?.birthdate
              ) : loader === true ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                ""
              )}
            </div>
          </section>
          {/* <section>
            <div>
              <FlagOutlinedIcon />
              <p className="m-0 p-0">{t("common.country")}</p>
            </div>
            <div>
              {infoDetails !== null ? (
                infoDetails?.company_branch === null ? (
                  <div>No branches</div>
                ) : (
                  <a
                    href={
                      infoDetails !== null
                        ? `https://www.google.com/maps/?q=${infoDetails?.company_branch?.address?.latitude},${infoDetails?.company_branch?.address?.longitude}`
                        : ""
                    }
                    target="_blank"
                  >
                    <ArrowRightOutlinedIcon />
                  </a>
                )
              ) : (
                ""
              )}
            </div>
          </section>
          <section>
            <div>
              <FlagOutlinedIcon />
              <p className="m-0 p-0">{t("common.city")}</p>
            </div>
            <div>
              {infoDetails !== null ? (
                infoDetails?.company_branch === null ? (
                  <div>No branches</div>
                ) : (
                  <a
                    href={
                      infoDetails !== null
                        ? `https://www.google.com/maps/?q=${infoDetails?.company_branch?.address?.latitude},${infoDetails?.company_branch?.address?.longitude}`
                        : ""
                    }
                    target="_blank"
                  >
                    <ArrowRightOutlinedIcon />
                  </a>
                )
              ) : (
                ""
              )}
            </div>
          </section> */}
          {/* <section>
            <div>
              <LocalPhoneOutlinedIcon />
              <p className="m-0 p-0">{t("common.phone")}</p>
            </div>
            <div>
              {infoDetails !== null ? (
                infoDetails?.mobile
              ) : loader === true ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                ""
              )}
            </div>
          </section> */}
          <section>
            <div>
              <KeyOutlinedIcon />
              <p className="m-0 p-0">{t("profile.changePassword")}</p>
            </div>
            <div
              onClick={handleOpenChangePasswordModal}
              style={{ cursor: "pointer" }}
            >
              <ArrowRightOutlinedIcon />
            </div>
          </section>
          <section>
            <div>
              <ShieldOutlinedIcon />
              <p className="m-0 p-0">{t("profile.privacyPolicy")}</p>
            </div>
            <div>
              <a
                href={
                  infoDetails !== null
                    ? infoDetails?.privacy_content?.vendor
                    : "#"
                }
                target="_blank"
                className="cursor"
              >
                <ArrowRightOutlinedIcon />
              </a>
            </div>
          </section>
          <section>
            <div>
              <ShieldOutlinedIcon />
              <p className="m-0 p-0">{t("profile.termsConditions")}</p>
            </div>
            <div>
              <a
                href={
                  infoDetails !== null ? infoDetails?.terms_content?.vendor : "#"
                }
                target="_blank"
                className="cursor"
              >
                <ArrowRightOutlinedIcon />
              </a>
            </div>
          </section>
        </CardContent>
      </Card>
    </>
  );
}
