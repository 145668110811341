import { useSelector } from "react-redux";

import GroupIcon from "@mui/icons-material/Group";

import UsersColumns from "../components/Users/UsersColumns";

import { getUsers, getUsersWithFilter } from "../store/actions/users";

import { setFrom, setTo } from "../store/slices/users";

export default function UsersRoute() {
  const { isLoading, data } = useSelector((state) => state?.users);

  return {
    path: "users",
    pageName: "users",
    apiCall: getUsers,
    apiCallWithFilter: getUsersWithFilter,
    isLoading,
    IconPath: GroupIcon,
    data,
    tableColumns: UsersColumns,
    setFrom,
    setTo,
  };
}
