import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";

export const getDashboardData = createAsyncThunk(
  "activities/getDashboardData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get("/auth/api/sales_commissions");
      return res.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message)
        return rejectWithValue(error?.response?.data?.message);
      else {
        return rejectWithValue(error?.message);
      }
    }
  }
);
