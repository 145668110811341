import OrdersRoute from "./OrdersRoute";
import OrderRoute from "./OrderRoute";
import DeliveringRoute from "./DeliveringRoute";
import TripsRoute from "./TripsRoute";
import VendorsSubscriptionsRoute from "./VendorsSubscriptionsRoute";
import SalesCommissions from "./SalesRoute";
import VendorsCommissions from "./VendorsRoute";
import RidersCommissions from "./RidersRoute";
import UsersRoute from "./UsersRoute";

export default function globalRoute() {
  return [
    OrdersRoute(),
    OrderRoute(),
    DeliveringRoute(),
    VendorsSubscriptionsRoute(),
    TripsRoute(),
    SalesCommissions(),
    VendorsCommissions(),
    RidersCommissions(),
    UsersRoute(),
  ];
}
