import { useTranslation } from "react-i18next";

import SignInFields from "../../components/SignIn/SignInFields";

export default function SignInRoute() {
  const { t } = useTranslation();

  return {
    path: "",
    pageName: "signIn",
    pageTitle: `${t("common.welcomeTo")} ${t("common.forAll")}`,

    Fields: SignInFields,
  };
}
