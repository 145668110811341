import { createSlice } from "@reduxjs/toolkit";
import {
  changelanguage,
  getAddress,
  getCurrencies,
  getLanguages,
  getProfileData,
  getCountries,
} from "../actions/profile";
import { handleShowError } from "../../functions/handling";
import { toast } from "react-toastify";
import i18n from "../../i18n/config";
const { t } = i18n;

const initialState = {
  data: null,
  currencies: null,
  languages: null,
  countriesdata: null,
  address: [],
  isLoading: false,
  isLoadingForLanguages: false,
  isLoadingForCurrencies: false,
  error: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfileData.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getProfileData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getProfileData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getAddress.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAddress.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.address = payload?.data;
    },
    [getAddress.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    [getCurrencies.pending]: (state) => {
      state.isLoadingForCurrencies = true;
      state.error = null;
    },
    [getCurrencies.fulfilled]: (state, { payload }) => {
      state.isLoadingForCurrencies = false;
      state.currencies = payload?.data;
    },
    [getCurrencies.rejected]: (state, { payload }) => {
      state.isLoadingForCurrencies = false;
      state.error = payload;
    },

    [getLanguages.pending]: (state) => {
      state.isLoadingForLanguages = true;
      state.error = null;
    },
    [getLanguages.fulfilled]: (state, { payload }) => {
      state.isLoadingForLanguages = false;
      state.languages = payload?.data;
    },
    [getLanguages.rejected]: (state, { payload }) => {
      state.isLoadingForLanguages = false;
      state.error = payload;
    },

    [changelanguage.pending]: (state) => {
      state.isLoadingForLanguages = true;
    },
    [changelanguage.fulfilled]: (state, { payload }) => {
      state.isLoadingForLanguages = false;
    },
    [changelanguage.rejected]: (state, { payload }) => {
      state.isLoadingForLanguages = false;
      state.error = payload;
      handleShowError(state?.error);
    },
    [getCountries.pending]: (state) => {
      state.error = null;
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countriesdata = payload?.data;
    },
    [getCountries.rejected]: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default profileSlice.reducer;
