import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";

import { handleError } from "../../functions/handling";

const requestUri = `/operation/orders?type=orders_bills`;

export const getDelivering = createAsyncThunk(
  "delivering/getDelivering",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(requestUri);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getDeliveringWithFilter = createAsyncThunk(
  "delivering/getDeliveringWithFilter",
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        delivering: { from, to },
      } = getState();

      const res = await ApiConfig.get(`${requestUri}&from=${from}&to=${to}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
