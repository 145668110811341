import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";

import { handleError } from "../../functions/handling";

// const requestUri = `/operation/users?from=${from}&to=${to}`;
const requestUri = `/operation/users`;

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(requestUri);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getUsersWithFilter = createAsyncThunk(
  "users/getUsersWithFilter",
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        users: { from, to },
      } = getState();

      const res = await ApiConfig.get(`${requestUri}?from=${from}&to=${to}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(`${requestUri}?id=${id}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
