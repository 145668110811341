import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function UsersColumns() {
  const { t } = useTranslation();

  return [
    {
      name: "item.id",
      label: t("common.id"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.name",
      label: t("common.name"),
      options: {
        customBodyRender: (value) => (
          <div className="font-weight-bold">{value}</div>
        ),
      },
    },
    {
      name: "item.type.role",
      label: t("common.type"),
      options: {
        customBodyRender: (value) => (
          <div className="font-weight-bold">{value}</div>
        ),
      },
    },
    {
      name: "item.payments_cards.length",
      label: t("common.paymentsCards"),
      options: {
        customBodyRender: (value) => (
          <div className="font-weight-bold">{value}</div>
        ),
      },
    },
    {
      name: "item.birthdate",
      label: t("common.birthdate"),
      options: {
        customBodyRender: (value) => (
          <div className="font-weight-bold">{value}</div>
        ),
      },
    },
    {
      name: "item.created_at",
      label: t("common.createdAt"),
      options: {
        customBodyRender: (value) => (
          <div className="font-weight-bold">{value}</div>
        ),
      },
    },
    {
      name: "item",
      label: t("common.view"),
      options: {
        customBodyRender: (value) => (
          <div>
            <Tooltip title={t("common.user")}>
              <span>
                <IconButton>
                  <Link to={`/dashboard/users/${value?.id}`}>
                    <VisibilityOutlinedIcon />
                  </Link>
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ),
      },
    },
  ];
}
