import { useSelector } from "react-redux";

import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";

import DeliveringColumns from "../components/delivering/DeliveringColumns";

import {
  getDelivering,
  getDeliveringWithFilter,
} from "../store/actions/delivering";

import { setFrom, setTo } from "../store/slices/delivering";

export default function DeliveringRoute() {
  const { isLoading, data } = useSelector((state) => state?.delivering);

  return {
    path: "delivering",
    pageName: "delivering",
    apiCall: getDelivering,
    apiCallWithFilter: getDeliveringWithFilter,
    isLoading,
    IconPath: MarkunreadMailboxIcon,
    data,
    tableColumns: DeliveringColumns,
    setFrom,
    setTo,
  };
}
