import {
  confirm_password,
  new_password,
  password,
} from "../../../utils/inputValidations/profile";
import { Input } from "../../global/Form/Input";

export default function FieldsForUpdatePassword() {
  return (
    <div>
      <Input {...password} />
      <Input {...new_password} />
      <Input {...confirm_password} />
    </div>
  );
}
