import { useTranslation } from "react-i18next";

import ForgotPasswordFields from "../../components/SignIn/ForgotPasswordFields";

export default function ForgotPasswordRoute() {
  const { t } = useTranslation();

  return {
    path: "forgot-password",
    pageName: "forgot-password",
    pageTitle: t("common.forgotPassword"),

    Fields: ForgotPasswordFields,
  };
}
