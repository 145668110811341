import i18n from "../../i18n/config";
const { t } = i18n;
export const name = {
  name: "name",
  label: t("common.name"),
  id: "name",
  placeholder: t("common.name"),
  validation: {
    required: {
      value: true,
      message: t("common.required"),
    },
  },
};

export const email = {
  name: "email",
  label: t("common.email"),
  id: "email",
  placeholder: t("common.email"),
  validation: {
    required: {
      value: true,
      message: t("common.required"),
    },
    pattern: {
      value: /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-zA-Z]{2,4}/,
      message: t("common.notEmail"),
    },
  },
};

export const country_code = {
  name: "country_code",
  id: "name",
  placeholder: t("common.countryCode"),
};

export const mobile = {
  name: "mobile",
  id: "mobile",
  placeholder: t("common.newPhone"),
  validation: {
    required: {
      value: true,
      message: t("common.required"),
    },
  },
};
