import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { findInputError, isFormInvalid } from "../../../../utils";

import "../index.scss";
import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const SelectInput = ({
  name,
  label,
  id,
  placeholder,
  value,
  validation,
  options,
  multiple,
  customWidth,
  DontShowplaceholder,
}) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const { t } = useTranslation();

  useEffect(() => {
    setValue(name, value);
  }, [name, value, setValue]);

  return (
    <div className={customWidth ? "inputComponent w-100" : "inputComponent"}>
      <div className="labelAndError">
        <label htmlFor={id}>{label}</label>
        <AnimatePresence mode="wait" initial={false}>
          {isInvalid && (
            <InputError
              message={inputErrors.error.message}
              key={inputErrors.error.message}
            />
          )}
        </AnimatePresence>
      </div>
      <FormControl className="select">
        <Controller
          name={name}
          control={control}
          defaultValue={value ? value : []}
          render={({ field }) => (
            <Select
              {...field}
              {...register(name, validation)}
              displayEmpty={true}
              renderValue={(value) => {
                if (value?.length > 0 || typeof value === "number") {
                  if (Array.isArray(value)) {
                    let values = [];
                    value?.map((item) =>
                      options?.find((option) => {
                        if (option?.value === item) values.push(option?.label);
                      })
                    );
                    return values.join(", ");
                  }
                  const item = options?.find(({ value: v }) => v === value);
                  return item?.label;
                } else
                  return !DontShowplaceholder
                    ? `${t("common.pleaseSelect")} ${placeholder}`
                    : "";
              }}
              multiple={multiple ? true : false}
              MenuProps={{
                style: {
                  maxHeight: 200,
                },
              }}
            >
              {options?.map(({ label, value, icon }, i) => {
                if (icon) {
                  return (
                    <MenuItem key={i} value={value}>
                      <ListItemIcon>
                        <img alt="icon" src={icon} />
                      </ListItemIcon>
                      <ListItemText sx={{ margin: "0 5px" }} primary={label} />
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={i} value={value}>
                      {label}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <motion.p className="inputError" {...framer_error}>
      <ErrorOutlineOutlinedIcon />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};
