import i18n from "../../i18n/config";
const { t } = i18n;

export const password = {
  name: "current_password",
  label: t("common.password"),
  id: "password",
  placeholder: t("common.password"),
  type: "password",
  validation: {
    required: {
      value: true,
      message: t("common.required"),
    },
    minLength: {
      value: 6,
      message: t("common.passwordValidation"),
    },
  },
};

export const new_password = {
  name: "password",
  label: t("common.newPassword"),
  id: "new_password",
  placeholder: t("common.newPassword"),
  type: "password",
  validation: {
    required: {
      value: true,
      message: t("common.required"),
    },
    minLength: {
      value: 6,
      message: t("common.passwordValidation"),
    },
  },
};

export const confirm_password = {
  name: "password_confirmation",
  label: t("common.confirmPassword"),
  id: "confirm_password",
  placeholder: t("common.confirmPassword"),
  type: "password",
  validation: {
    required: {
      value: true,
      message: t("common.required"),
    },
    minLength: {
      value: 6,
      message: t("common.passwordValidation"),
    },
  },
};
