import InnerCard from "./InnerCard";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import ModalImage from "react-modal-image";
import { CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./index.scss";

export default function UserDetails({ userData, loader }) {
  const { t } = useTranslation();
  return (
    <div className="userDetailsCard">
      <div className="profile">
        <div className="imgContainer">
          {userData?.image != null ? (
            <ModalImage
              smallSrcSet={userData?.image?.["512px"]}
              large={userData?.image?.["512px"]}
              hideDownload={true}
              hideZoom={true}
              alt="user image"
            />
          ) : (
            <ModalImage
              smallSrcSet="/assets/images/avatar.png"
              large="/assets/images/avatar.png"
              hideDownload={true}
              hideZoom={true}
              alt="company image"
            />
          )}
        </div>
        <div className="data">
          <h4>
            {loader ? <CircularProgress color="inherit" /> : userData?.name}
          </h4>
          <p>
            {t("common.joined")}: <span>{userData?.created_at}</span>
          </p>
          <p>
            {t("common.birthdate")}: <span>{userData?.birthdate}</span>
          </p>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <InnerCard
            title={t("common.location")}
            text={userData?.address}
            type="location"
            Icon={LocationOnIcon}
            loader={loader}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <InnerCard
            title={t("common.phone")}
            text={userData?.mobile}
            type="phone"
            Icon={CallIcon}
            loader={loader}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <InnerCard
            title={t("common.email")}
            text={userData?.email}
            type="email"
            Icon={EmailIcon}
            loader={loader}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
          <InnerCard
            title={t("common.gender")}
            text={userData?.gender}
            type="gender"
            Icon={MaleIcon}
            SecondIcon={FemaleIcon}
            loader={loader}
          />
        </Grid>
      </Grid>
    </div>
  );
}
