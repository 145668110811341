import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

// pages
import Layout from "./layout";
import SignInLayout from "./layout/SignInLayout";
import Global from "./pages/Global";
import SignInGlobal from "./pages/SignInGlobal";
import Home from "./pages/home";
import Profile from "./pages/Profile";
import User from "./pages/User";
import { CircularProgress, Dialog } from "@mui/material";
import globalRoute from "./routes";
import globalSignInRoutes from "./routes/singInRoutes";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "swagger-ui-react/swagger-ui.css";
import { useSelector } from "react-redux";

function App() {
  const { i18n } = useTranslation();

  const { isLoadingForLanguages, isLoading } = useSelector(
    (state) => state.profile
  );
  let navigate = useNavigate();
  let lang = localStorage.getItem("lang") || sessionStorage.getItem("lang");

  useEffect(() => {
    if (localStorage.getItem("lang") || localStorage.getItem("lang")) {
      document
        .getElementsByTagName("html")[0]
        .setAttribute(
          "lang",
          localStorage.getItem("lang") || localStorage.getItem("lang")
        );
    }

    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <>
      <Routes>
        {/* SignIn routes */}
        <Route path="/" element={<SignInLayout />}>
          {globalSignInRoutes().map(({ path, pageTitle, Fields }, i) => (
            <Route
              key={i}
              path={path}
              element={
                <SignInGlobal
                  path={path}
                  pageTitle={pageTitle}
                  Fields={Fields}
                />
              }
            />
          ))}
        </Route>

        {/* Global routes */}
        <Route path="dashboard" element={<Layout />}>
          {globalRoute().map(
            (
              {
                path,
                pageName,
                apiCall,
                apiCallWithFilter,
                isLoading,
                title,
                IconPath,
                description,
                tableTitle,
                data,
                tableColumns,
                typeOfPage,
                setFrom,
                setTo,
                withoutFilter,
              },
              i
            ) => (
              <Route
                key={i}
                path={path}
                element={
                  <Global
                    path={path}
                    pageName={pageName}
                    apiCall={apiCall}
                    apiCallWithFilter={apiCallWithFilter}
                    isLoading={isLoading}
                    title={title}
                    IconPath={IconPath}
                    description={description}
                    tableTitle={tableTitle}
                    data={data}
                    tableColumns={tableColumns}
                    typeOfPage={typeOfPage}
                    setFrom={setFrom}
                    setTo={setTo}
                    withoutFilter={withoutFilter}
                  />
                }
              />
            )
          )}

          <Route path="home" element={<Home />} />

          {/* Profile */}
          <Route path="profile" element={<Profile />} />

          {/* User */}
          <Route path="users/:id" element={<User />} />
        </Route>
      </Routes>
      <ToastContainer
        hideProgressBar={true}
        position={lang == "en" ? "bottom-left" : "bottom-right"}
      />
      <Dialog open={isLoadingForLanguages || isLoading ? true : false}>
        <div className="dialog-loading">
          <CircularProgress className="loading" />
        </div>
      </Dialog>
    </>
  );
}

export default App;
