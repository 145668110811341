import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  CircularProgress,
  MenuItem,
  Typography,
  Avatar,
  Menu,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuIcon from "@mui/icons-material/Menu";
import TranslateIcon from "@mui/icons-material/Translate";
import { getLanguages, logout } from "../../../store/actions/auth";
import "./index.scss";
import { changelanguage, getProfileData } from "../../../store/actions/profile";

export default function Navbar({
  toggleMenu,
  handleToggleMenu,
  handleDrawerToggle,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { languagesdata } = useSelector((state) => state.auth);
  const { isLoading, data } = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getProfileData());
  }, []);

  let lang = localStorage.getItem("lang") || sessionStorage.getItem("lang");

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [toggleSearch, setToggleSearch] = useState(null);
  const [langMenu, setLangMenu] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLangMenu = (event) => {
    setLangMenu(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setLangMenu(null);
  };

  const handleToggleSearch = () => {
    setToggleSearch(!toggleSearch);
  };

  const handleChangeLang = async (lang, Id) => {
    localStorage.setItem("langId", Id);
    sessionStorage.setItem("langId", Id);
    document.documentElement.lang = lang;
    localStorage.setItem("lang", lang);
    sessionStorage.setItem("lang", lang);
    await dispatch(changelanguage({ id: Id && Number(Id) }));
    i18n.changeLanguage(lang).then(() => {
      window.location.reload(true);
    });

    handleCloseLangMenu();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(navigate));
  };

  return (
    <div className={`navbar-component ${toggleMenu ? "activeMenu" : ""}`}>
      <div className="toggleMenu" onClick={handleToggleMenu}>
        <MenuIcon fontSize="small" />
      </div>
      <div className="actions">
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className="drawerButton"
        >
          <MenuIcon />
        </IconButton>
      </div>
      <div className="content">
        <div
          onClick={handleToggleSearch}
          className={`searchInput ${toggleSearch ? "active" : ""}`}
        >
          <SearchIcon fontSize="small" />
          <input placeholder={t("navbar.search")} type="text" />
        </div>
        <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
          {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt="Avatar"
              src={image ? image["512px"] : "/assets/images/Avatar.png"}
            />
          </IconButton> */}
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt="Avatar"
              src={
                data?.image != null
                  ? data?.image?.["512px"]
                  : "/assets/images/Avatar.png"
              }
            />
          </IconButton>
          <h6 className="username" style={{ margin: "0px 20px 0 10px" }}>
            {isLoading && data != null ? (
              <p className="fs-6">
                <CircularProgress className="loading" />
              </p>
            ) : (
              <>
                {t("navbar.welcome")}, {data?.name !== null ? data?.name : ""}
              </>
            )}
          </h6>

          <div className="profileModal">
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              className="menuAppBarContainer menuAppBarUser"
            >
              <div className="menuAppBar">
                <Link
                  to="/dashboard/profile"
                  className="d-flex align-items-center justify-content-center gap-3"
                >
                  <MenuItem
                    onClick={handleCloseUserMenu}
                    sx={{ textAlign: "center", padding: "10px" }}
                    className="navProfile d-flex align-items-center justify-content-center gap-3"
                  >
                    <AccountCircleIcon />
                    <Typography textAlign="center">
                      {t("navbar.profile")}
                    </Typography>
                  </MenuItem>
                </Link>
                <MenuItem
                  sx={{ textAlign: "center", padding: "10px" }}
                  onClick={handleLogout}
                  className="d-flex align-items-center justify-content-center gap-3"
                >
                  <LogoutIcon />

                  <Typography textAlign="center" sx={{ fontSize: "15px" }}>
                    {t("navbar.logout")}
                  </Typography>
                </MenuItem>
              </div>
            </Menu>
          </div>

          {/* <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            className="menuAppBarContainer"
          >
            <div className="menuAppBar">
              <MenuItem onClick={handleCloseUserMenu} sx={{ paddingX: "10px" }}>
                <Link to="/dashboard/profile">
                  <Typography textAlign="center">
                    {t("navbar.profile")}
                  </Typography>
                </Link>
              </MenuItem>

              <MenuItem
                sx={{ textAlign: "center", paddingX: "10px" }}
                onClick={handleLogout}
              >
                <Typography textAlign="center">{t("navbar.logout")}</Typography>
              </MenuItem>
            </div>
          </Menu> */}

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar-lang"
            anchorEl={langMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(langMenu)}
            onClose={handleCloseLangMenu}
            className="menuAppBarContainer"
          >
            <div className="menuAppBar">
              {languagesdata?.map((item) => {
                if (item?.symbols == lang) {
                  return (
                    <MenuItem key={item?.id} className="activeLang">
                      <Typography sx={{ cursor: "pointer" }} textAlign="center">
                        {item?.name_values?.value}
                      </Typography>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem
                      key={item?.id}
                      onClick={() => handleChangeLang(item?.symbols, item?.id)}
                    >
                      <Typography sx={{ cursor: "pointer" }} textAlign="center">
                        {item?.name_values?.value}
                      </Typography>
                    </MenuItem>
                  );
                }
              })}
            </div>
          </Menu>
          <Tooltip
            title={t("navbar.languages")}
            className="tooltipForResponsive"
          >
            <IconButton onClick={handleOpenLangMenu} sx={{ p: 0 }}>
              <TranslateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t("navbar.showGuide")}
            className="tooltipForResponsive"
          >
            <IconButton>
              <HelpOutlineIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </div>
  );
}
