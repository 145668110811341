import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom";

import BreadCrumb from "../../components/global/BreadCrumb";
import Table from "../../components/global/Table/index";

import "./index.scss";
import CommissionModal from "../../components/global/Modals/CommissionModal";
import PaymentModal from "../../components/global/Modals/PaymentModal";
import { trasnferCommission } from "../../store/actions/sales";

const Global = ({
  path,
  pageName,
  apiCall,
  apiCallWithFilter,
  isLoading,
  IconPath,
  data,
  tableColumns,
  typeOfPage,
  setFrom,
  setTo,
  withoutFilter,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  let navigate = useNavigate();

  const [openCommissionsModal, setOpenCommissionsModal] = useState(false);
  const [commissionsModalData, setCommissionsModalData] = useState(null);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [PaymentModalData, setPaymentModalData] = useState(null);
  const [trackId, setTrackId] = useState(null);

  useEffect(() => {
    if (typeOfPage === "internal") {
      dispatch(apiCall({ id }));
    } else {
      dispatch(apiCall(navigate));
    }
  }, [dispatch, path, id, typeOfPage]);

  const handleOpenCommissionsModal = () => {
    setOpenCommissionsModal(true);
  };
  const handleCloseCommissionsModal = () => {
    setOpenCommissionsModal(false);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const handleOpenPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  return (
    <div className="globalPage">
      <BreadCrumb />
      <div className="pageHeader">
        <h2 className="pageTitle">
          <IconPath />
          {t(`${pageName}.pageTitle`)}
        </h2>
        <p>{t(`${pageName}.description`)}</p>
      </div>
      <Table
        path={path}
        pageName={pageName}
        apiCall={apiCall}
        apiCallWithFilter={apiCallWithFilter}
        isLoading={isLoading}
        data={data}
        tableColumns={tableColumns}
        setCommissionsModalData={setCommissionsModalData}
        handleOpenCommissionsModal={handleOpenCommissionsModal}
        setPaymentModalData={setPaymentModalData}
        handleOpenPaymentModal={handleOpenPaymentModal}
        setFrom={setFrom}
        setTo={setTo}
        withoutFilter={withoutFilter}
        setTrackId={setTrackId}
      />
      <CommissionModal
        open={openCommissionsModal}
        handleClose={handleCloseCommissionsModal}
        trackId={trackId}
        apiCallForTransfer={trasnferCommission}
      />

      <PaymentModal
        CardBrand={PaymentModalData?.CardBrand}
        PaymentType={PaymentModalData?.PaymentType}
        amount={PaymentModalData?.amount}
        billNumber={PaymentModalData?.billNumber}
        cardToken={PaymentModalData?.cardToken}
        open={openPaymentModal}
        handleClose={handleClosePaymentModal}
      />
    </div>
  );
};

export default Global;
