import { Grid } from "@mui/material";

import "./index.scss";

export default function CardForNumbers({ Icon, text, numbers, type }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <div className="cardForNumbers">
        <Icon />
        <div className="data">
          <h4>{text}</h4>
          <p>
            {numbers} <span>{type}</span>
          </p>
        </div>
      </div>
    </Grid>
  );
}
