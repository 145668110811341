import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";
import { handleError } from "../../functions/handling";
import { removeFalsyProps } from "../../functions/functionsForEdit";
import i18n from "../../i18n/config";
import { getProfileData } from "./profile";
import { changelanguage } from "../actions/profile";
import { useNavigate } from "react-router-dom";
const { t } = i18n;

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    let lang = localStorage.getItem("lang") || sessionStorage.getItem("lang");
    let langId =
      localStorage.getItem("langId") || sessionStorage.getItem("langId");
    try {
      const res = await ApiConfig.post("/auth/api/login", {
        email,
        password,
      });

      if (res?.data?.success && (res?.data?.data?.type?.id === 15 || 14 || 1)) {
        sessionStorage.setItem("token", res?.data?.data?.token);
        if (sessionStorage.getItem("token") && lang && langId) {
          await dispatch(changelanguage({ id: Number(langId) }));
        } else {
          i18n.changeLanguage(res?.data?.data?.language?.symbols);
          localStorage.setItem("lang", res?.data?.data?.language?.symbols);
          sessionStorage.setItem("lang", res?.data?.data?.language?.symbols);
        }
        return res?.data;
      } else {
        return rejectWithValue(t("signIn.youNotHavePermission"));
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      if (error?.response?.status === 422 || 401) {
        console.log("error", error);
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (navigate, { rejectWithValue }) => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("image");
      localStorage.removeItem("id");
      localStorage.removeItem("userId");
      localStorage.removeItem("companyId");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("image");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("companyId");
      navigate("/");
      const res = await ApiConfig.get("/auth/api/logout");
      if (res?.data?.success) {
        navigate("/");
        return true;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      handleError(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (data, { rejectWithValue, dispatch }) => {
    let navigate = useNavigate();
    const finalData = removeFalsyProps(data);
    let bodyFormData = new FormData();
    bodyFormData.append("_method", "put");
    finalData?.id && bodyFormData.append("id", finalData?.id);
    finalData?.name && bodyFormData.append("name", finalData?.name);
    finalData?.email && bodyFormData.append("email", finalData?.email);
    finalData?.country_code &&
      bodyFormData.append(
        "mobile",
        `(${finalData?.country_code.slice(1)})${finalData?.mobile}`
      );

    try {
      const res = await ApiConfig.post("/auth/api/system_user", bodyFormData);

      if (res?.data?.success) {
        dispatch(getProfileData());
        return true;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      if (error.response.status >= 400 && error.response.status <= 409) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        navigate("/");
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.post("/auth/api/change-password", {
        ...data,
      });
      if (res?.data?.success) {
        return true;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.post("/auth/api/forget-password", {
        ...data,
      });
      if (res?.data?.success) {
        return res?.data?.message;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const verifyToken = createAsyncThunk(
  "auth/verifyToken",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.post("/auth/api/verify-token", {
        ...data,
      });
      if (res?.data?.success) {
        localStorage.setItem("tokenForResetPassword", res?.data?.data?.token);
        return res?.data?.message;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.post("/auth/api/reset-password", {
        ...data,
      });
      if (res?.data?.success) {
        return res?.data?.message;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getLanguages = createAsyncThunk(
  "auth/getLanguages",
  async (navigate, { rejectWithValue }) => {
    try {
      const languagesRes = await ApiConfig.get("/setting/api/languages");
      if (languagesRes?.data?.success) {
        return languagesRes?.data;
      } else {
        return rejectWithValue(languagesRes?.data?.message);
      }
    } catch (error) {
      if (error.response.status >= 400 && error.response.status <= 409) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        navigate("/");
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
