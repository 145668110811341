import { useSelector } from "react-redux";

import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import OrdersColumns from "../components/Orders/OrdersColumns";

import { getOrders, getOrdersWithFilter } from "../store/actions/orders";

import { setFrom, setTo } from "../store/slices/orders";

export default function OrdersRoute() {
  const { isLoading, data } = useSelector((state) => state?.orders);

  return {
    path: "orders",
    pageName: "orders",
    apiCall: getOrders,
    apiCallWithFilter: getOrdersWithFilter,
    isLoading,
    IconPath: ShoppingBagIcon,
    data,
    tableColumns: OrdersColumns,
    setFrom,
    setTo,
  };
}
