import { useEffect } from "react";
import "./index.scss";

const DarkMode = () => {
  let defaultDark = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "dark";
  // 1
  const setDark = () => {
    // 2
    localStorage.setItem("theme", "dark");
    defaultDark = "dark";
    // 3
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    defaultDark = "light";
    document.documentElement.setAttribute("data-theme", "light");
  };

  // 4

  // const prefersDark =
  //   window.matchMedia &&
  //   window.matchMedia("(prefers-color-scheme: dark)").matches;

  const toggleTheme = (e) => {
    if (e.target.checked) {
      console.log("e.target.checked", e.target.checked);
      setDark();
    } else {
      setLight();
    }
  };
  useEffect(() => {
    if (defaultDark === "dark") {
      setDark();
    } else {
      setLight();
    }
  }, []);

  return (
    <div className="toggle-theme-wrapper">
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          defaultChecked={defaultDark == "dark" ? true : false}
        />
        <div className="slider round"></div>
      </label>
    </div>
  );
};

export default DarkMode;
