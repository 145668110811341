import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import TokenIcon from "@mui/icons-material/Token";
import EditIcon from "@mui/icons-material/Edit";
import InnerCard from "./InnerCard";
import ModalImage from "react-modal-image";
import "./index.scss";
import EditModal from "../../global/Modals/EditModal";
import { useState } from "react";
import { updateProfile } from "../../../store/actions/auth";
import FieldForEditProfile from "./../FieldForEditProfile";

const UserDetails = ({ infoDetails, loader, isLoadingUpdateProfAction }) => {
  const { t } = useTranslation();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
    detailed_address: "",
  });

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  return (
    <>
      <div className="userDetailsCard">
        <div className="profile">
          <div className="imgContainer">
            {infoDetails?.image?.["512px"] != null ? (
              <ModalImage
                smallSrcSet={infoDetails?.image?.["512px"]}
                large={infoDetails?.image?.["512px"]}
                hideDownload={true}
                hideZoom={true}
                alt="user image"
              />
            ) : (
              <ModalImage
                smallSrcSet="/assets/images/avatar.png"
                large="/assets/images/avatar.png"
                hideDownload={true}
                hideZoom={true}
                alt="company image"
              />
            )}
          </div>

          <div className="data">
            <div className="name-id d-lg-flex">
              <h2>{infoDetails?.name}</h2>
              <span className="fs-5 fw-bold  alternativecolor">
                {infoDetails?.alternative_id != null
                  ? `#${infoDetails?.alternative_id}`
                  : `#${infoDetails?.id}`}
              </span>
            </div>

            <p>{infoDetails?.type?.role}</p>
          </div>
        </div>
        <div className="cards">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InnerCard
                title={t("common.location")}
                text={infoDetails?.address}
                type="location"
                Icon={LocationOnIcon}
                loader={loader}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InnerCard
                title={t("common.phone")}
                text={infoDetails?.mobile}
                type="phone"
                Icon={CallIcon}
                loader={loader}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InnerCard
                title={t("common.email")}
                text={infoDetails?.email}
                type="email"
                Icon={EmailIcon}
                loader={loader}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
              <InnerCard
                title={t("common.gender")}
                text={infoDetails?.gender}
                type="gender"
                Icon={MaleIcon}
                SecondIcon={FemaleIcon}
                loader={loader}
              />
            </Grid>
          </Grid>
        </div>
        <p
          className="editIconProfile cursor"
          onClick={() => handleOpenEditModal()}
        >
          <EditIcon /> {t("common.edit")}
        </p>
      </div>

      <EditModal
        open={openEditModal}
        handleClose={handleCloseEditModal}
        apiCallForEdit={updateProfile}
        editModalData={infoDetails}
        Fields={FieldForEditProfile}
        isLoading={loader}
        isLoadingForAction={isLoadingUpdateProfAction}
      />
    </>
  );
};

export default UserDetails;

// import { useTranslation } from "react-i18next";
// import { CircularProgress, Grid } from "@mui/material";

// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import CallIcon from "@mui/icons-material/Call";
// import EmailIcon from "@mui/icons-material/Email";
// import MaleIcon from "@mui/icons-material/Male";
// import FemaleIcon from "@mui/icons-material/Female";
// import TokenIcon from "@mui/icons-material/Token";

// import InnerCard from "./InnerCard";

// import "./index.scss";

// const UserDetails = ({ infoDetails, loader }) => {
//   const { t } = useTranslation();

//   return (
//     <div className="userDetailsCard">
//       <div className="profile">
//         <div className="profileImg">
//           {loader ? (
//             <CircularProgress color="inherit" />
//           ) : (
//             <img
//               src={
//                 infoDetails?.image
//                   ? infoDetails?.image["512px"]
//                   : "/assets/images/avatar.png"
//               }
//               alt="profile"
//             />
//           )}
//         </div>

//         <div className="data">
//           <h4>
//             {loader ? <CircularProgress color="inherit" /> : infoDetails?.name}
//           </h4>
//           <p>{infoDetails?.type?.role}</p>
//         </div>
//       </div>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
//           <InnerCard
//             title={t("common.location")}
//             text={infoDetails?.address}
//             type="location"
//             Icon={LocationOnIcon}
//             loader={loader}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
//           <InnerCard
//             title={t("common.phone")}
//             text={infoDetails?.mobile}
//             type="phone"
//             Icon={CallIcon}
//             loader={loader}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
//           <InnerCard
//             title={t("common.email")}
//             text={infoDetails?.email}
//             type="email"
//             Icon={EmailIcon}
//             loader={loader}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
//           <InnerCard
//             title={t("common.gender")}
//             text={infoDetails?.gender}
//             type="gender"
//             Icon={MaleIcon}
//             SecondIcon={FemaleIcon}
//             loader={loader}
//           />
//         </Grid>
//         {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
//           <InnerCard
//             title={t("common.token")}
//             text={infoDetails?.token}
//             type="token"
//             Icon={TokenIcon}
//             loader={loader}
//           />
//         </Grid> */}
//       </Grid>
//       <div className="details"></div>
//     </div>
//   );
// };

// export default UserDetails;
