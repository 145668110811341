import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

import "./index.scss";
import { getProfileData } from "../../../store/actions/profile";

export default function Token({ infoDetails, loader }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.profile);

  const [isCopied, setIsCopied] = useState(false);
  const [token, setToken] = useState(infoDetails?.token);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopy = () => {
    copyTextToClipboard(infoDetails?.token)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setToken(infoDetails?.token);
  }, [infoDetails]);
  return (
    <>
      <h2 className="mainTitleForPage">{t("common.settings")}</h2>
      <Card className="tokenCard">
        <CardContent>
          <section>
            <div>
              <TokenOutlinedIcon />
              <p className="mb-0">{t("common.token")}</p>
            </div>
            <div>
              <LockOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={handleOpen}
              />
            </div>
          </section>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="tokenDialog"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "4px",
          }}
          id="alert-dialog-title"
        >
          {t("common.token")}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {isCopied ? (
              <CheckOutlinedIcon />
            ) : (
              <ContentCopyOutlinedIcon
                sx={{ cursor: "pointer" }}
                onClick={handleCopy}
              />
            )}
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <RefreshIcon
                sx={{ cursor: "pointer" }}
                onClick={() => dispatch(getProfileData())}
              />
            )}
          </div>
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: "0" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ wordWrap: "break-word" }}
          >
            {token}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
