import { createSlice } from "@reduxjs/toolkit";

import { getUser, getUsers, getUsersWithFilter } from "../actions/users";

import {
  getDateWithSpecFormat,
  handleShowError,
} from "../../functions/handling";

import dayjs from "dayjs";

const initialState = {
  data: null,
  user: null,
  from: "2020-01-01",
  to: getDateWithSpecFormat(dayjs()),
  isLoading: false,
  error: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setFrom: (state, { payload }) => {
      state.from = getDateWithSpecFormat(payload);
    },
    setTo: (state, { payload }) => {
      state.to = getDateWithSpecFormat(payload);
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getUsersWithFilter.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getUsersWithFilter.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getUsersWithFilter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getUser.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.user = payload?.data;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },
  },
});

export const { setFrom, setTo } = usersSlice.actions;

export default usersSlice.reducer;
