import { getLatLng } from "use-places-autocomplete";

// getKeyAndValueFromObjectDataForApiCall

// data =>  object contain multi values (name, and desc and ....)
// we need to extract name for example as an array this array is an array of strings (name with all languages "17") => ["name", "الاسم", "..."]

// keyOrValue
// data object like this is shape   {"name-en": "hello", "name-ar": "مرحبا", "name-fr": "...", ...};
// if keyOrValue equal 0 we return array for values like this ["hello", "مرحبا", "..."]
// else we return array of languages keys ["en", "ar", "fr", "..."]

// value
// name or description or .. any value we need

// this function return array of strings

export function getKeyAndValueFromData(data, value) {
  let arrayForValues = [];
  let arrayForKeys = [];
  Object.keys(data).forEach((key) => {
    if (key.split("-")[0] === value) {
      arrayForValues.push(data[key]);
      arrayForKeys.push(key.split("-")[1]);
    }
  });
  return [arrayForValues, arrayForKeys];
}

export function getTimeWithSpFormat(date, withoutTime) {
  if (withoutTime) {
    return `${new Date(date).getFullYear()}-${
      new Date(date).getMonth() + 1 < 10
        ? `0${new Date(date).getMonth() + 1}`
        : new Date(date).getMonth() + 1
    }-${
      new Date(date).getDate() < 10
        ? `0${new Date(date).getDate()}`
        : new Date(date).getDate()
    }`;
  } else {
    return `${new Date(date).getFullYear()}-${
      new Date(date).getMonth() + 1 < 10
        ? `0${new Date(date).getMonth() + 1}`
        : new Date(date).getMonth() + 1
    }-${
      new Date(date).getDate() < 10
        ? `0${new Date(date).getDate()}`
        : new Date(date).getDate()
    } ${
      new Date(date).getHours() < 10
        ? `0${new Date(date).getHours()}`
        : new Date(date).getHours()
    }:${
      new Date(date).getMinutes() < 10
        ? `0${new Date(date).getMinutes()}`
        : new Date(date).getMinutes()
    }`;
  }
}
export function getHAndMWithSpFormat(date) {
  return ` ${
    new Date(date).getHours() < 10
      ? `0${new Date(date).getHours()}`
      : new Date(date).getHours()
  }:${
    new Date(date).getMinutes() < 10
      ? `0${new Date(date).getMinutes()}`
      : new Date(date).getMinutes()
  }`;
}

// this function to remove falsy value from object data send to api
export function removeFalsyProps(obj) {
  for (var key in obj) {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === "" ||
      obj[key]?.length === 0
    ) {
      delete obj[key];
    }
  }

  return obj;
}

// this function to return city and country from address results of google map
export function getLocationDataFromAddress(results) {
  let city;
  let country;
  let detailed_address = results?.[0]?.formatted_address;
  let { lat, lng } = getLatLng(results[0]);

  results[0]?.["address_components"]?.map((item) => {
    if (
      item?.types[0] === "administrative_area_level_2" ||
      item?.types[0] === "administrative_area_level_1"
    ) {
      city = item?.long_name;
    }

    if (item?.types[0] === "country") {
      country = item?.long_name;
    }
  });

  return { city, country, detailed_address, lat, lng };
}
