import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CircularProgress, FormControl, OutlinedInput } from "@mui/material";

import { verifyToken } from "../../store/actions/auth";

import "./index.scss";

export default function VerifyFields({ pageTitle }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingForAction } = useSelector((state) => state.auth);
  const [token, setToken] = useState("");
  const email = localStorage.getItem("emailForVerifyToken");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(verifyToken({ token, email }));
    if (!res?.error) {
      localStorage.removeItem("emailForVerifyToken");
      navigate("/reset-password");
    }
  };

  return (
    <>
      <h3>{pageTitle}</h3>
      <form onSubmit={handleSubmit} method="post">
        <FormControl fullWidth className="formControl disabledInput">
          <label>{t("common.email")}</label>
          <OutlinedInput
            placeholder={t("common.email")}
            name="email"
            type="text"
            value={email}
            required
            disabled
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <label>{t("common.token")}</label>
          <OutlinedInput
            placeholder={t("common.token")}
            name="token"
            type="text"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
          />
        </FormControl>

        <button type="submit" disabled={isLoadingForAction}>
          {isLoadingForAction ? <CircularProgress /> : t("common.verify")}
        </button>
      </form>
    </>
  );
}
