import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import GroupIcon from "@mui/icons-material/Group";

import { useTranslation } from "react-i18next";

export const Links = () => {
  const { t } = useTranslation();

  return [
    {
      title: t("sideNav.dashboard"),
      path: "home",
      keyForActive: "home",
      icon: <DashboardIcon />,
    },
    {
      title: t("sideNav.orders"),
      path: "orders",
      keyForActive: "orders",
      icon: <ShoppingBagIcon />,
    },
    {
      title: t("sideNav.delivering"),
      path: "delivering",
      keyForActive: "delivering",
      icon: <MarkunreadMailboxIcon />,
    },
    {
      title: t("sideNav.bookedCars"),
      path: "trips",
      keyForActive: "trips",
      icon: <FmdGoodIcon />,
    },
    {
      title: t("sideNav.vendorsSubscriptions"),
      path: "vendors",
      keyForActive: "vendors",
      icon: <PersonIcon />,
    },

    {
      title: t("sideNav.moneyTransfer"),
      keyForActive: "sales-commissions",
      icon: <CurrencyExchangeIcon />,
      items: [
        {
          title: t("sideNav.sales"),
          path: "sales-commissions",
          keyForActive: "sales-commissions",
          icon: <CurrencyExchangeIcon />,
        },
        {
          title: t("sideNav.vendors"),
          path: "vendors-commissions",
          keyForActive: "vendors-commissions",
          icon: <CurrencyExchangeIcon />,
        },
        {
          title: t("sideNav.riders"),
          path: "riders-commissions",
          keyForActive: "riders-commissions",
          icon: <CurrencyExchangeIcon />,
        },
      ],
    },
    {
      title: t("sideNav.users"),
      path: "users",
      keyForActive: "users",
      icon: <GroupIcon />,
    },
  ];
};
