import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { resetPassword } from "../../store/actions/auth";

import "./index.scss";

export default function ResetPasswordFields({ pageTitle }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingForAction } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowPasswordConf = () => setShowPasswordConf((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConf = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(
      resetPassword({
        password: password,
        password_confirmation: passwordConfirmation,
      })
    );
    if (!res?.error) {
      localStorage.removeItem("tokenForResetPassword");
      navigate("/");
    }
  };

  return (
    <>
      <h3>{pageTitle}</h3>
      <form onSubmit={handleSubmit} method="post">
        <FormControl fullWidth className="formControl">
          <label>{t("common.password")}</label>
          <OutlinedInput
            placeholder={t("common.password")}
            id="outlined-adornment-password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <label>{t("common.confirmationPassword")}</label>
          <OutlinedInput
            placeholder={t("common.confirmationPassword")}
            id="outlined-adornment-password"
            name="password_confirmation"
            type={showPasswordConf ? "text" : "password"}
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPasswordConf}
                  onMouseDown={handleMouseDownPasswordConf}
                  edge="end"
                >
                  {showPasswordConf ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <button type="submit" disabled={isLoadingForAction}>
          {isLoadingForAction ? <CircularProgress /> : t("common.reset")}
        </button>
      </form>
    </>
  );
}
