import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ChangePasswordModal from "../../components/global/Modals/ChangePasswordModal";
import FieldsForUpdatePassword from "../../components/Profile/Settings/FieldsForUpdatePassword";
import UserDetails from "../../components/Profile/UserDetails";
import Token from "../../components/Profile/Token/Token";
import Settings from "../../components/Profile/Settings/Settings";
import { CircularProgress, Grid } from "@mui/material";
import "./index.scss";
import { getProfileData } from "../../store/actions/profile";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const { isLoadingForAction } = useSelector((state) => state.auth);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const userInfo = JSON.parse(
    localStorage.getItem("userInfo") || sessionStorage.getItem("userInfo")
  );

  const { isLoading, isLoadingUpdateProfAction, data, error } = useSelector(
    (state) => state.profile
  );

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getProfileData(navigate));
  }, []);

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  return (
    <div className="profilePage">
      {isLoading ? (
        <p className="w-100 ploader">
          <CircularProgress className="loading" />
        </p>
      ) : (
        <>
          <div className="pageHeader">
            <h2 className="pageTitle">{t("profile.pageTitle")}</h2>
          </div>
          <UserDetails infoDetails={data} loader={isLoading} />

          <Token infoDetails={data} loader={isLoading} />
          <Settings
            infoDetails={data}
            loader={isLoading}
            handleOpenChangePasswordModal={handleOpenChangePasswordModal}
          />
          <ChangePasswordModal
            open={openChangePasswordModal}
            handleClose={handleCloseChangePasswordModal}
            Fields={FieldsForUpdatePassword}
            isLoadingForAction={isLoadingForAction}
          />
        </>
      )}
    </div>
  );
}
