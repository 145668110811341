import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ar";
import "dayjs/locale/en";

// import { handleChangeFrom, handleChangeTo } from "../../../fuctions/datePicker";

import "./index.scss";

export default function DatePickerComponent({
  apiCallWithFilter,
  setFrom,
  setTo,
}) {
  let { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="datePickerContainer">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={localStorage.getItem("i18nextLng")}
      >
        <MobileDatePicker
          views={["day", "month", "year"]}
          inputFormat="DD/MM/YYYY"
          label={t("common.from")}
          onChange={(newValue) => {
            dispatch(setFrom(newValue));
          }}
          onAccept={async () => {
            await dispatch(apiCallWithFilter());
          }}
          renderInput={(params) => (
            <TextField {...params} helperText={null} id="startDate" />
          )}
          maxDate={dayjs()}
          minDate={dayjs("2020")}
        />
      </LocalizationProvider>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={localStorage.getItem("i18nextLng")}
      >
        <MobileDatePicker
          views={["day", "month", "year"]}
          inputFormat="DD/MM/YYYY"
          label={t("common.to")}
          onChange={(newValue) => {
            dispatch(setTo(newValue));
          }}
          onAccept={async () => {
            await dispatch(apiCallWithFilter());
          }}
          renderInput={(params) => (
            <TextField {...params} helperText={null} id="endDate" />
          )}
          maxDate={dayjs()}
          minDate={dayjs("2020")}
        />
      </LocalizationProvider>
    </div>
  );
}
