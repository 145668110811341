import { useTranslation } from "react-i18next";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import "./index.scss";

export default function BreadCrumb() {
  const { pathname } = useLocation();

  const pathnames = pathname.split("/").filter(Boolean);
  const { t } = useTranslation();

  let internalPage = "";
  let subInternalPage = "";

  return (
    <Breadcrumbs
      // separator={
      //   document.dir === "ltr" ? (
      //     <NavigateNextIcon fontSize="small" />
      //   ) : (
      //     <NavigateBeforeIcon fontSize="small" />
      //   )
      // }
      separator={"-"}
      aria-label="breadcrumb"
      className="breadcrumbComponent"
    >
      {pathnames.map((name, index) => {
        let routeTo;
        if (index === 0) {
          routeTo = `/${pathnames.slice(0, index + 1).join("/")}/home`;
        } else {
          routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        }

        if (index === 1) {
          if (name === "orders") internalPage = "ordersDetails";
          if (name === "reports") internalPage = "files";
          if (name === "products") {
            internalPage = "productMenu";
            subInternalPage = "inventory";
          }
        }
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name}>
            {t(
              `breadcrumb.${
                index === 2
                  ? internalPage
                  : index === 3
                  ? subInternalPage
                  : name
              }`
            )}
          </Typography>
        ) : (
          <Link key={name} to={routeTo}>
            {t(`breadcrumb.${index === 2 ? internalPage : name}`)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
