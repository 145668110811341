import { Grid } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";

import CardForNumbers from "./CardForNumbers";

import "./index.scss";

export default function Cards() {
  return (
    <Grid container spacing={3}>
      <CardForNumbers
        Icon={GroupIcon}
        text="Clients"
        numbers="50,000"
        type="Clients"
      />
      <CardForNumbers
        Icon={GroupIcon}
        text="Clients"
        numbers="50,000"
        type="Clients"
      />
      <CardForNumbers
        Icon={GroupIcon}
        text="Clients"
        numbers="50,000"
        type="Clients"
      />
      <CardForNumbers
        Icon={GroupIcon}
        text="Clients"
        numbers="50,000"
        type="Clients"
      />
    </Grid>
  );
}
