import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import "./index.scss";

export default function EditModal({
  open,
  handleClose,
  apiCallForEdit,
  editModalData,
  isLoadingForAction,
  isLoading,
  Fields,
  location,
  setLocation,
  error,
  inputNameEdit,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const methods = useForm();
  const onSubmit = methods.handleSubmit(async (data) => {
    console.log();
    const res = await dispatch(
      apiCallForEdit({
        address_id: editModalData?.address?.id,
        ...data,
        id: editModalData?.id,
        ...location,
        user_id: editModalData?.user_id,
        product_menu_id: editModalData?.product_menu_id,
        product_system_id: editModalData?.product?.id,
      })
    );

    if (!res?.error) {
      methods.reset();

      handleClose();
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="editDialog"
    >
      <DialogTitle
        sx={{ display: "flex", alignItems: "center", gap: "4px" }}
        id="alert-dialog-title"
      >
        <EditIcon /> {t("common.edit")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("common.editText")}
        </DialogContentText>
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            noValidate
            autoComplete="off"
            id="editForm"
          >
            {Fields && (
              <Fields data={editModalData} inputNameEdit={inputNameEdit} />
            )}
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className="dialogActions" sx={{ gap: "16px" }}>
        <button onClick={handleClose}>{t("common.cancel")}</button>
        <button
          form="editForm"
          type="submit"
          disabled={isLoadingForAction || isLoading}
          autoFocus
        >
          {t("common.edit")}
        </button>
      </DialogActions>
    </Dialog>
  );
}
