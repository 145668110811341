import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";
import { handleError } from "../../functions/handling";
const requestUriCountry = "/setting/api/countries";

export const getProfileData = createAsyncThunk(
  "profile/getProfileData",
  async (navigate, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get("/auth/api/my-info");
      if (res?.data?.success) {
        return res?.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/");
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getAddress = createAsyncThunk(
  "profile/getAddress",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get("/setting/api/addresses");
      return res.data;
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getLanguages = createAsyncThunk(
  "profile/getLanguages",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get("/setting/api/languages");
      return res.data;
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const changelanguage = createAsyncThunk(
  "profile/changelanguage",
  async ({ id }, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      formData.append("_method", "put");
      formData.append("lang_id", id);
      const res = await ApiConfig.post("/auth/api/system_user", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res?.data?.success) {
        return true;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getCountries = createAsyncThunk(
  "profile/getCountries",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(requestUriCountry);
      return res.data;
    } catch (error) {
      if (error?.response && error?.response?.data?.message)
        return rejectWithValue(error?.response?.data?.message);
      else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getCurrencies = createAsyncThunk(
  "profile/getCurrencies",
  async (_, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get("/setting/api/currencies");
      return res.data;
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
