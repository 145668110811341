import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../components/global/Navbar";
import SideNav from "../components/global/SideNav";

import "./index.scss";
import { Box, Drawer } from "@mui/material";

const Layout = () => {
  const navigate = useNavigate();
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  useEffect(() => {
    if (
      localStorage.getItem("token") === null &&
      sessionStorage.getItem("token") === null
    ) {
      navigate("/");
    }

    document.body.style.paddingTop = "64px";
  }, [navigate, token]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => setToggleMenu(!toggleMenu);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (e) => {
    setMobileOpen((prevState) => !prevState);
  };

  const { pathname } = useLocation();
  const isChatPage = pathname?.split("/")[2];

  return (
    <div className="mainLayout">
      <Navbar
        handleToggleMenu={handleToggleMenu}
        toggleMenu={toggleMenu}
        handleDrawerToggle={handleDrawerToggle}
      />
      <SideNav handleToggleMenu={handleToggleMenu} toggleMenu={toggleMenu} />
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: 240,
            },
          }}
        >
          <SideNav
            handleToggleMenu={handleToggleMenu}
            toggleMenu={toggleMenu}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Drawer>
      </Box>
      <main
        className={`${toggleMenu ? "toggleMenu" : ""} ${
          isChatPage === "chat" ? "isChatPage" : ""
        }`}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
