import { useTranslation } from "react-i18next";

import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link } from "react-router-dom";

export default function VendorsSubscriptionsColumns(
  one,
  two,
  three,
  setPaymentModalData,
  handleOpenPaymentModal
) {
  const { t } = useTranslation();

  const handlePayment = (value) => {
    handleOpenPaymentModal();
    setPaymentModalData({
      PaymentType: value?.PaymentType,
      amount: value?.amount,
      billNumber: value?.billNumber,
      sadadNumber: value?.sadadNumber,
    });
  };

  return [
    {
      name: "item.id",
      label: t("common.forAllId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.Transaction_id",
      label: t("common.yourWayId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.payment_response.TranId",
      label: t("common.transactionId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.details",
      label: t("common.details"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.type",
      label: t("common.companyType"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.payment_response",
      label: t("common.yourWayResponse"),
      options: {
        customBodyRender: (value) => (
          <div>
            <span>{value?.Result}</span>
          </div>
        ),
      },
    },
    {
      name: "item.state",
      label: t("common.forAllResponse"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    // {
    //   name: "item.id",
    //   label: t("common.view"),
    //   options: {
    //     customBodyRender: (value) => (
    //       <div className="m-auto">
    //         <button
    //           className="btn btn-primary btn-app text-white"
    //           onClick={() => {
    //             singleVendorTransactionsView("companies", `&id=${value}`);
    //             document.getElementById("vendorView").style.display = "flex";
    //           }}
    //         >
    //           {t("common.view")}
    //         </button>
    //       </div>
    //     ),
    //   },
    // },
    {
      name: "item",
      label: t("common.view"),
      options: {
        customBodyRender: (value) => (
          <div>
            <Tooltip
              title={t("common.view")}
              onClick={() => handlePayment(value?.payment_response)}
            >
              <span>
                <IconButton>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ),
      },
    },
  ];
}
