import SignInRoute from "./signInRoute";
import ForgotPasswordRoute from "./forgotPassword";
import VerifyRoute from "./verifyRoute";
import ResetPasswordRoute from "./resetPasswordRoute";

export default function globalSignInRoutes() {
  return [
    SignInRoute(),
    ForgotPasswordRoute(),
    VerifyRoute(),
    ResetPasswordRoute(),
  ];
}
