import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";

import WidgetsIcon from "@mui/icons-material/Widgets";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export default function OrderColumns() {
  let { t } = useTranslation();

  return [
    {
      name: "item.id",
      label: t("common.id"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.destination.street",
      label: t("common.description"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.discount_price",
      label: t("common.discount"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.shipping_price",
      label: t("common.price"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.order_rider.name",
      label: t("common.rider"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.state",
      label: t("common.state"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item",
      label: t("common.actions"),
      options: {
        customBodyRender: (value) => (
          <div className="d-flex">
            <Tooltip title={t("common.product")}>
              <IconButton
                size="large"
                // onClick={() => {
                //   document.getElementById("productView").style.display = "flex";
                // }}
              >
                <WidgetsIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("common.provider")}>
              <IconButton
                size="large"
                // onClick={() => {
                //   filtershipping(ordersData, value);
                //   document.getElementById("providerView").style.display =
                //     "flex";
                // }}
              >
                <InventoryIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("common.shipper")}>
              <IconButton
                // onClick={() => {
                //   filtershipping(ordersData, value);
                //   document.getElementById("deliveryView").style.display =
                //     "flex";
                // }}
                size="large"
              >
                <LocalShippingIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    },
  ];
}
