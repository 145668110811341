import { CircularProgress } from "@mui/material";

export default function InnerCard({
  Icon,
  SecondIcon,
  title,
  text,
  type,
  loader,
}) {
  return (
    <div className="innerCard">
      {type === "gender" ? (
        text === "male" ? (
          <Icon />
        ) : (
          <SecondIcon />
        )
      ) : (
        <Icon />
      )}
      <p className="title">{title}</p>
      <p>
        {loader ? (
          <CircularProgress color="inherit" />
        ) : type === "location" ? (
          <a
            href={
              text
                ? `https://www.google.com/maps/?q=${text?.latitude},${text?.longitude}`
                : ""
            }
            target="blank"
          >
            {text?.city_id?.country_id?.name?.key}
          </a>
        ) : (
          text
        )}
      </p>
    </div>
  );
}
