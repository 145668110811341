import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyTableDark } from "../../../images/EmptyTableDark.svg";
import { ReactComponent as EmptyTableLight } from "../../../images/EmptyTableLight.svg";

import "./index.scss";

export default function EmptyTable() {
  const theme = localStorage.getItem("theme");
  const { t } = useTranslation();

  return (
    <div className="emptyTable">
      {theme === "light" ? <EmptyTableLight /> : <EmptyTableDark />}
      <h3>{t("common.sorry")}</h3>
      <p>{t("common.noDataYet")}</p>
    </div>
  );
}
