import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import EmptyTable from "../EmptyTable";
import CustomToolbar from "./CustomToolbar";

export default function Table({
  pageName,
  isLoading,
  data,
  tableColumns,
  setCommissionsModalData,
  handleOpenCommissionsModal,
  setPaymentModalData,
  handleOpenPaymentModal,
  apiCallWithFilter,
  setFrom,
  setTo,
  withoutFilter,
  setTrackId,
}) {
  const { t } = useTranslation();

  const options = {
    filter: false,
    viewColumns: false,
    print: false,
    selectableRows: "none",
    searchPlaceholder: t("common.search"),
    responsive: "standard",
    page: 0,
    enableNestedDataAccess: ".",
    rowsPerPage: 5,
    rowsPerPageOptions: [0],

    // tableBodyHeight: "550px",
    customToolbar: () => (
      <CustomToolbar
        apiCallWithFilter={apiCallWithFilter}
        setFrom={setFrom}
        setTo={setTo}
        withoutFilter={withoutFilter}
      />
    ),
    textLabels: {
      body: {
        noMatch: isLoading
          ? t("common.loading")
          : (!data || data?.length === 0) && <EmptyTable />,
      },
      pagination: {
        next: "Next >",
        previous: "< Previous",
        displayRows: t("common.of"),
      },
      toolbar: {
        search: t("common.search"),
        downloadCsv: t("common.download"),
        print: t("common.print"),
      },
    },
  };

  return (
    <MUIDataTable
      className="muiDataTableCustom"
      title={
        <Typography variant="h6">
          {t(`${pageName}.tableTitle`)}
          {isLoading ? (
            <CircularProgress
              size={24}
              style={{ marginLeft: 15, position: "relative", top: 4 }}
            />
          ) : (
            <span className="mx-2">({data?.length})</span>
          )}
        </Typography>
      }
      data={
        data?.length > 0
          ? data?.map((item) => {
              return { item: item };
            })
          : []
      }
      columns={
        tableColumns()?.length > 0
          ? tableColumns(
              setCommissionsModalData,
              handleOpenCommissionsModal,
              setTrackId,
              setPaymentModalData,
              handleOpenPaymentModal
            )
          : []
      }
      options={options}
    />
  );
}
