import { useSelector } from "react-redux";

import PersonIcon from "@mui/icons-material/Person";

import VendorsSubscriptionsColumns from "../components/VendorsSubscriptions/VendorsSubscriptionsColumns";

import {
  getVendorsSubscriptions,
  getVendorsSubscriptionsWithFilter,
} from "../store/actions/vendorsSubscriptions";

import { setFrom, setTo } from "../store/slices/vendorsSubscriptions";

export default function VendorsSubscriptionsRoute() {
  const { isLoading, data } = useSelector(
    (state) => state?.vendorsSubscriptions
  );

  return {
    path: "vendors",
    pageName: "vendorsSubscriptions",
    apiCall: getVendorsSubscriptions,
    apiCallWithFilter: getVendorsSubscriptionsWithFilter,
    isLoading,
    IconPath: PersonIcon,
    data,
    tableColumns: VendorsSubscriptionsColumns,
    setFrom,
    setTo,
  };
}
