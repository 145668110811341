import { useSelector } from "react-redux";

import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import OrderColumns from "../components/Orders/Order/OrderColumns";

import { getOrder } from "../store/actions/orders";

export default function OrderRoute() {
  const { isLoading, data } = useSelector((state) => state?.orders);

  return {
    path: "orders/:id",
    pageName: "order",
    apiCall: getOrder,
    isLoading,
    IconPath: ShoppingBagIcon,
    data,
    typeOfPage: "internal",
    tableColumns: OrderColumns,
    withoutFilter: true,
  };
}
