import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import DarkMode from "../../components/global/DarkMode";
import {
  Grid,
  IconButton,
  Tooltip,
  MenuItem,
  Typography,
  Menu,
} from "@mui/material";

import { ReactComponent as Logo } from "../../images/inv_qr.svg";
import { ReactComponent as SignInIll } from "../../images/SignInIll.svg";
import { ReactComponent as GooglePlay } from "../../images/GooglePlay.svg";
import { ReactComponent as AppStore } from "../../images/AppStore.svg";

import HelpIcon from "@mui/icons-material/Help";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import TranslateIcon from "@mui/icons-material/Translate";

import { setRemember } from "../../store/slices/auth";
import { getLanguages, login } from "../../store/actions/auth";

import "./index.scss";

const SignInLayout = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { languagesdata } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      navigate("/dashboard/home");
    }
    document.body.style.paddingTop = 0;
  }, [navigate, isLoggedIn]);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(login({ email, password }));
  // };

  const [langMenu, setLangMenu] = useState(null);

  const handleOpenLangMenu = (event) => {
    setLangMenu(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setLangMenu(null);
  };

  const handleChangeLang = (lang, id) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("langId", id);
    sessionStorage.setItem("langId", id);
    document.documentElement.lang = lang;
    localStorage.setItem("lang", lang);
    sessionStorage.setItem("lang", lang);
    handleCloseLangMenu();
  };

  return (
    <div className="singInPage">
      <Grid container>
        <Grid
          className="right"
          item
          xs={12}
          sx={{ marginBottom: { xs: "50px", lg: "0" } }}
          lg={6}
        >
          <div className="options">
            <Grid container>
              <Grid item xs={6}>
                <Tooltip
                  title={t("navbar.languages")}
                  className="tooltipForResponsive"
                >
                  <IconButton onClick={handleOpenLangMenu} sx={{ p: 0 }}>
                    <TranslateIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar-lang"
                  anchorEl={langMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(langMenu)}
                  onClose={handleCloseLangMenu}
                  className="menuAppBarContainer"
                >
                  <div className="menuAppBar">
                    {languagesdata?.map((item) => (
                      <MenuItem
                        key={item?.id}
                        onClick={() =>
                          handleChangeLang(item?.symbols, item?.id)
                        }
                      >
                        <Typography
                          sx={{ cursor: "pointer" }}
                          textAlign="center"
                        >
                          {item?.name_values?.value}
                        </Typography>
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Brightness4Icon />
                  <p className="mb-0">{t("sideNav.mode")}</p>
                  <DarkMode />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title={t("navbar.showGuide")}>
                  <IconButton>
                    <HelpIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          <div className="form">
            <div className="forall-logo text-center">
              <Logo />
            </div>
            <Outlet />
          </div>
        </Grid>
        <Grid className="left" item xs={12} lg={6}>
          <div className="logoContainer"></div>
          <p className="app-links text-center"> {t("sideNav.appLog")}</p>
          <div className="storesContainer text-center d-flex justify-content-center">
            <img src="/assets/pattern-mobile.svg" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default SignInLayout;
