import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";

import { handleError } from "../../functions/handling";
import { useNavigate } from "react-router-dom";

const requestUri = `/operation/commission?type=sales`;

let transferUrl = "/auth/api/sales_commissions";

export const getSales = createAsyncThunk(
  "sales/getSales",

  async (navigate, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(requestUri);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/");
      if (error.response.status >= 400 && error.response.status <= 409) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        navigate("/");
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getSalesWithFilter = createAsyncThunk(
  "sales/getSalesWithFilter",
  async (navigate, { rejectWithValue, getState }) => {
    try {
      const {
        sales: { from, to },
      } = getState();

      const res = await ApiConfig.get(`${requestUri}&from=${from}&to=${to}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      if (error.response.status >= 400 && error.response.status <= 409) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        navigate("/");
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const trasnferCommission = createAsyncThunk(
  "sales/trasnferCommission",
  async ({ navigate, trackId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await ApiConfig.post(`${transferUrl}?id=${trackId}`);
      if (res?.data?.success) {
        await dispatch(getSales());
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      if (error.response.status >= 400 && error.response.status <= 409) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        navigate("/");
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
