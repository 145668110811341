import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function TripsColumns() {
  let { t } = useTranslation();

  return [
    {
      name: "item.id",
      label: t("common.orderId"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.user_id",
      label: t("common.userId"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value?.id}</div>,
      },
    },
    {
      name: "item.user_id.name",
      label: t("common.client"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.cost",
      label: t("common.totalPayment"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },

    {
      name: "item.created_at",
      label: t("common.time"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.state",
      label: t("common.status"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.payment_transaction.Transaction_id",
      label: t("common.trackId"),
      headerAlign: "center",
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item",
      label: t("common.view"),
      options: {
        customBodyRender: (value) => (
          <div>
            <Tooltip title={t("common.view")}>
              <span>
                <IconButton>
                  {/* <Link to={`/dashboard/users/${value?.id}`}> */}
                  <VisibilityOutlinedIcon />
                  {/* </Link> */}
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ),
      },
    },
    // {
    //   name: "item",
    //   label: t("common.view"),
    //   options: {
    //     customBodyRender: (value) => {
    //       return (
    //         <div className="m-auto">
    //           {value?.state === "in_payment" ? (
    //             ""
    //           ) : (
    //             <>
    //               <div className="m-auto">
    //                 <button
    //                   className="btn btn-primary btn-app text-white"
    //                   onClick={() => {
    //                     singleOrderForView("book_trip", value?.id);
    //                     document.getElementById("vendorView").style.display =
    //                       "flex";
    //                   }}
    //                 >
    //                   {t("common.view")}
    //                 </button>
    //               </div>
    //             </>
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];
}
