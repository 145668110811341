import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";

import { handleError } from "../../functions/handling";

const requestUri = `/operation/payments_transactions`;

export const getVendorsSubscriptions = createAsyncThunk(
  "vendorsSubscriptions/getVendorsSubscriptions",
  async (navigate, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(requestUri);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/");
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getVendorsSubscriptionsWithFilter = createAsyncThunk(
  "vendorsSubscriptions/getVendorsSubscriptionsWithFilter",
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        vendorsSubscriptions: { from, to },
      } = getState();

      const res = await ApiConfig.get(`${requestUri}?from=${from}&to=${to}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
