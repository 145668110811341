import { useTranslation } from "react-i18next";

import { IconButton, Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function DeliveringColumns() {
  let { t } = useTranslation();

  return [
    {
      name: "item.id",
      label: t("common.id"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.order_user.id",
      label: t("common.userId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.order_user.name",
      label: t("common.client"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },

    {
      name: "item.order_rider.id",
      label: t("common.driverId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.order_riderS.name",
      label: t("common.driver"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.rider_commission",
      label: t("common.driverCommissions"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },

    {
      name: "item.shipping_price",
      label: t("common.shippingPrice"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },

    {
      name: "item.state",
      label: t("common.status"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item",
      label: t("common.view"),
      options: {
        customBodyRender: (value) => (
          <div>
            <Tooltip title={t("common.view")}>
              <span>
                <IconButton>
                  {/* <Link to={`/dashboard/users/${value?.id}`}> */}
                  <VisibilityOutlinedIcon />
                  {/* </Link> */}
                </IconButton>
              </span>
            </Tooltip>
          </div>
        ),
      },
    },
    // {
    //   name: "item",
    //   label: t("common.view"),
    //   options: {
    //     customBodyRender: (value) => (
    //       <div className="m-auto">
    //         {value.state === "in_payment" ? (
    //           ""
    //         ) : (
    //           <button
    //             className="btn btn-primary btn-app text-white"
    //             onClick={() => {
    //               singleOrderForView("orders_bills", value?.id);
    //               document.getElementById("vendorView").style.display = "flex";
    //             }}
    //           >
    //             {t("common.view")}
    //           </button>
    //         )}
    //       </div>
    //     ),
    //   },
    // },
  ];
}
