import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CircularProgress, FormControl, OutlinedInput } from "@mui/material";

import { forgotPassword } from "../../store/actions/auth";

export default function ForgotPasswordFields({ pageTitle }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingForAction } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await dispatch(forgotPassword({ email }));
    if (!res?.error) {
      localStorage.setItem("emailForVerifyToken", email);
      navigate("/verify-email");
    }
  };

  return (
    <>
      <h3 className="mt-5">{pageTitle}</h3>
      <form onSubmit={handleSubmit} method="post">
        <FormControl fullWidth className="formControl">
          <label>{t("common.email")}</label>
          <OutlinedInput
            placeholder={t("common.email")}
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>

        <button type="submit" disabled={isLoadingForAction}>
          {isLoadingForAction ? <CircularProgress /> : t("common.send")}
        </button>
      </form>
    </>
  );
}
