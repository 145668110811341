import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

export default function CommissionColumns(
  setCommissionsModalData,
  handleOpenCommissionsModal,
  setTrackId
) {
  const { t } = useTranslation();

  const handleView = (value) => {
    setTrackId(value.id);
    setCommissionsModalData(value);
    handleOpenCommissionsModal();
  };

  return [
    {
      name: "item.user_id.id",
      label: t("common.userId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.track_id",
      label: t("common.trackId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.id",
      label: t("common.commissionId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.user_id.name",
      label: t("common.user"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.user_id.type.role",
      label: t("common.role"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.type",
      label: t("common.type"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.amount",
      label: t("common.amount"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.forall_amount",
      label: t("common.commission"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.state",
      label: t("common.status"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    // {
    //   field: "transfer",
    //   headerName: "",
    //   headerClassName: "headerDashboard",
    //   headerAlign: "center",
    //   width: 90,
    //   flex: 0.5,
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div className="m-auto">
    //           <button
    //             className="btn btn-primary btn-app text-white"
    //             onClick={() => {
    //               type === "track_id"
    //                 ? getCommissionsForTrackIdView(
    //                     // params?.row?.track_id,
    //                     params?.row?.id
    //                   )
    //                 : getSpecificCommission(type, `&id=${params.row.id}`);

    //               document.getElementById("vendorView").style.display = "flex";
    //             }}
    //           >
    //             View
    //           </button>
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      name: "item",
      label: t("common.actions"),
      options: {
        customBodyRender: (value) => (
          <div>
            <Tooltip
              title={t("common.view")}
              onClick={() => handleView(value)}
              className="transfer-class"
            >
              <CurrencyExchangeIcon />
            </Tooltip>
          </div>
        ),
      },
    },
  ];
}
