import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";

import PaymentTransColumns from "./PaymentTransColumns";
import EmptyTable from "../../../global/EmptyTable";

import "./index.scss";

export default function PaymentTrans({ transactionsData, loader }) {
  const { t } = useTranslation();

  const options = {
    filter: false,
    viewColumns: false,
    selectableRows: "none",
    searchPlaceholder: t("common.search"),
    responsive: "standard",
    enableNestedDataAccess: ".",
    rowsPerPage: 8,
    rowsPerPageOptions: [0],

    tableBodyHeight: "550px",
    textLabels: {
      body: {
        noMatch: loader
          ? t("common.loading")
          : (!transactionsData || transactionsData?.length === 0) && (
              <EmptyTable />
            ),
      },
      pagination: {
        next: "Next >",
        previous: "< Previous",
        displayRows: t("common.of"),
      },
      toolbar: {
        search: t("common.search"),
        downloadCsv: t("common.download"),
        print: t("common.print"),
      },
    },
  };

  return (
    <div className="paymentTransComponent">
      <h2 className="mainTitleForPage">{t("common.paymentTransactions")}</h2>
      <MUIDataTable
        className="muiDataTableCustom"
        title={t("common.transactions")}
        data={
          transactionsData?.length > 0
            ? transactionsData?.map((item) => {
                return { item: item };
              })
            : []
        }
        columns={transactionsData?.length > 0 ? PaymentTransColumns() : []}
        options={options}
      />
    </div>
  );
}
