import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  // CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import Menu from "./Menu";
import DarkMode from "../DarkMode";
import { Links } from "./links";

import "./SideNav.scss";

import { ReactComponent as Logo } from "../../../images/Logo.svg";

import { logout } from "../../../store/actions/auth";

const SideNav = ({ toggleMenu, handleToggleMenu, mobileOpen }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(navigate));
  };

  return (
    <div
      className={`sideNav ${toggleMenu ? "activeMenu" : ""} ${
        mobileOpen ? "mobileOpen" : ""
      }`}
    >
      <div className="toggleMenu" onClick={handleToggleMenu}>
        <MenuIcon fontSize="small" />
      </div>
      <div className="logo">
        <Link to={"/dashboard/home"}>
          <Logo />
          {t("sideNav.accounting")}
        </Link>
      </div>
      <Menu items={Links()} />
      <List sx={{ marginTop: "50px" }}>
        <Link
          className={pathname.split("/")[2] === "profile" ? "activeLink" : ""}
          to="/dashboard/profile"
        >
          <ListItem button>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ display: "flex", justifyContent: "flex-start" }}
              primary={t("profile.pageTitle")}
            />
          </ListItem>
        </Link>

        <ListItem>
          <ListItemIcon>
            <Brightness4Icon />
          </ListItemIcon>

          <ListItemText
            sx={{ display: "flex", justifyContent: "flex-start" }}
            primary={t("sideNav.mode")}
          />
          <DarkMode />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText
            sx={{ display: "flex", justifyContent: "flex-start" }}
            primary={t("sideNav.logout")}
          />
        </ListItem>
      </List>
    </div>
  );
};

export default SideNav;
