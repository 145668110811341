import { createSlice } from "@reduxjs/toolkit";
import {
  updateProfile,
  forgotPassword,
  getLanguages,
  login,
  logout,
  resetPassword,
  updatePassword,
  verifyToken,
} from "../actions/auth";
import { toast } from "react-toastify";
import { handleShowError } from "../../functions/handling";

import i18n from "../../i18n/config";
const { t } = i18n;

const token = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;

const initialState = {
  languagesdata: null,
  isLoggedIn: false,
  isLoading: false,
  isLoadingForAction: false,
  isLoadingForLanguages: false,
  isLoadingUpdateProfAction: false,
  remember: false,
  error: null,
  token,
  userInfo: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRemember: (state) => {
      state.remember = true;
    },
  },
  extraReducers: {
    // login
    [login.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      state.token = payload?.data?.token;
      state.userInfo = payload?.data;
      if (payload != undefined) {
        if (state.remember) {
          localStorage.setItem("token", payload?.data?.token);
          // localStorage.setItem("name", payload?.data?.name);
          // localStorage.setItem(
          //   "image",
          //   JSON.stringify(payload?.data?.image?.["128px"])
          // );
          localStorage.setItem("userId", payload?.data?.id);
          // localStorage.setItem("companyId", payload?.data?.company?.id);
          localStorage.setItem("email", payload?.data?.email);
        } else {
          localStorage.setItem("token", payload?.data?.token);
          // sessionStorage.setItem("name", payload?.data?.name);
          // sessionStorage.setItem(
          //   "image",
          //   JSON.stringify(payload?.data?.image?.["128px"])
          // );
          sessionStorage.setItem("userId", payload?.data?.id);
          // sessionStorage.setItem("companyId", payload?.data?.company?.id);
        }
        toast.success(payload?.message);
      }
    },
    [login.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(payload);
    },

    [updateProfile.pending]: (state) => {
      state.isLoadingUpdateProfAction = true;
      state.error = null;
    },
    [updateProfile.fulfilled]: (state) => {
      state.isLoadingUpdateProfAction = false;
      toast.success(t("common.slices.UpdatedProfileSuccessfully"));
    },
    [updateProfile.rejected]: (state, { payload }) => {
      state.isLoadingUpdateProfAction = false;
      state.error = payload;
    },

    [logout.pending]: (state) => {
      state.error = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoading = false;
      state.isLoggedIn = false;
      state.token = null;
      state.userInfo = null;
    },
    [logout.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    [updatePassword.pending]: (state) => {
      state.isLoadingForAction = true;
      state.error = null;
    },
    [updatePassword.fulfilled]: (state) => {
      state.isLoadingForAction = false;
      toast.success(t("common.slices.passwordUpdatedSuccessfully"));
    },
    [updatePassword.rejected]: (state, { payload }) => {
      state.isLoadingForAction = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [forgotPassword.pending]: (state) => {
      state.isLoadingForAction = true;
      state.error = null;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.isLoadingForAction = false;
      toast.success(payload);
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.isLoadingForAction = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [verifyToken.pending]: (state) => {
      state.isLoadingForAction = true;
      state.error = null;
    },
    [verifyToken.fulfilled]: (state) => {
      state.isLoadingForAction = false;
      toast.success(t("tokenVerifiedSuccessfullyPleaseResetPassword"));
    },
    [verifyToken.rejected]: (state, { payload }) => {
      state.isLoadingForAction = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [resetPassword.pending]: (state) => {
      state.isLoadingForAction = true;
      state.error = null;
    },
    [resetPassword.fulfilled]: (state) => {
      state.isLoadingForAction = false;
      toast.success(t("passwordChangedSuccessfullyPleaseLoginAgain"));
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isLoadingForAction = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getLanguages.pending]: (state) => {
      state.isLoadingForLanguages = true;
      state.error = null;
    },
    [getLanguages.fulfilled]: (state, { payload }) => {
      state.languagesdata = payload.data;
      state.isLoadingForLanguages = false;
    },
    [getLanguages.rejected]: (state, { payload }) => {
      state.isLoadingForLanguages = false;
      state.error = payload;
    },
  },
});

export const { setRemember } = authSlice.actions;

export default authSlice.reducer;
