import { createSlice } from "@reduxjs/toolkit";

import { getOrder, getOrders, getOrdersWithFilter } from "../actions/orders";

import {
  getDateWithSpecFormat,
  handleShowError,
} from "../../functions/handling";

import dayjs from "dayjs";

const initialState = {
  data: null,
  from: "2020-01-01",
  to: getDateWithSpecFormat(dayjs()),
  isLoading: false,
  error: null,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setFrom: (state, { payload }) => {
      state.from = getDateWithSpecFormat(payload);
    },
    setTo: (state, { payload }) => {
      state.to = getDateWithSpecFormat(payload);
    },
  },
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getOrders.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getOrders.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getOrdersWithFilter.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getOrdersWithFilter.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getOrdersWithFilter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getOrder.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getOrder.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      if (typeof payload?.data === "object" && !Array.isArray(payload?.data)) {
        state.data = [payload?.data];
      } else {
        state.data = payload?.data;
      }
    },
    [getOrder.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },
  },
});

export const { setFrom, setTo } = ordersSlice.actions;

export default ordersSlice.reducer;
