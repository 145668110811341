import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import UserDetails from "../../components/Users/UserView/UserDetails";
import PaymentTrans from "../../components/Users/UserView/PaymentTrans";

import { getUser } from "../../store/actions/users";

export default function User() {
  let { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.users);
  let { id } = useParams();

  useEffect(() => {
    dispatch(getUser({ id }));
  }, [id, dispatch]);

  return (
    <div>
      <h2 className="mainTitleForPage">{t("common.user")}</h2>
      <UserDetails userData={user} loader={isLoading} />
      <PaymentTrans
        transactionsData={user?.payment_transactions}
        loader={isLoading}
      />
    </div>
  );
}
