import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function InnerCard({
  Icon,
  SecondIcon,
  title,
  text,
  type,
  loader,
}) {
  let { t } = useTranslation();
  return (
    <div className="innerCard">
      {type === "gender" ? (
        text === "male" ? (
          <Icon />
        ) : (
          <SecondIcon />
        )
      ) : (
        <Icon />
      )}
      <p className="title">{title}</p>
      <p className="text">
        {loader ? (
          <CircularProgress size={25} color="inherit" />
        ) : type === "location" ? (
          <a
            href={
              text
                ? `https://www.google.com/maps/?q=${text?.latitude},${text?.longitude}`
                : ""
            }
            target="blank"
          >
            {text?.city_id?.country_id?.name?.value}
          </a>
        ) : type === "token" ? (
          <Tooltip title={text}>
            <span>{`${text?.slice(0, 10)}...`}</span>
          </Tooltip>
        ) : type === "phone" ? (
          <p className="mt-2">
            {text != null && (
              <a className="text-decoration-none" href={`tel:${text}`}>
                {text}
              </a>
            )}
          </p>
        ) : type === "email" ? (
          <p className="mt-2 mb-2">
            {text != null && (
              <a className="text-decoration-none" href={`mailto:${text}`}>
                {text}
              </a>
            )}
          </p>
        ) : type === "gender" ? (
          <p className="mt-2 mb-2">{text != null && t(`common.${text}`)}</p>
        ) : (
          ""
        )}
      </p>
    </div>
  );
}

// import { CircularProgress, IconButton, Tooltip } from "@mui/material";

// export default function InnerCard({
//   Icon,
//   SecondIcon,
//   title,
//   text,
//   type,
//   loader,
// }) {
//   return (
//     <div className="innerCard">
//       {type === "gender" ? (
//         text === "male" ? (
//           <Icon />
//         ) : (
//           <SecondIcon />
//         )
//       ) : (
//         <Icon />
//       )}
//       <p className="title">{title}</p>
//       <p className="text">
//         {loader ? (
//           <CircularProgress size={25} color="inherit" />
//         ) : type === "location" ? (
//           <a
//             href={
//               text
//                 ? `https://www.google.com/maps/?q=${text?.latitude},${text?.longitude}`
//                 : ""
//             }
//             target="blank"
//           >
//             {text?.city_id?.country_id?.name?.key}
//           </a>
//         ) : type === "token" ? (
//           <Tooltip title={text}>
//             <span>{`${text?.slice(0, 10)}...`}</span>
//           </Tooltip>
//         ) : (
//           text
//         )}
//       </p>
//     </div>
//   );
// }
