import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function OrdersColumns() {
  let { t } = useTranslation();

  return [
    {
      name: "item.id",
      label: t("common.orderId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.user.id",
      label: t("common.userId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.user.name",
      label: t("common.client"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },

    {
      name: "item.inventory_count",
      label: t("common.totalItems"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.cost",
      label: t("common.totalPayment"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.created_at",
      label: t("common.time"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.state",
      label: t("common.status"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.payment_transaction.Transaction_id",
      label: t("common.trackId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item",
      label: t("common.view"),
      options: {
        customBodyRender: (value) => (
          <div className="m-auto">
            {value?.state === "in_payment" ? (
              ""
            ) : (
              <div>
                <Tooltip title={t("common.view")}>
                  <span>
                    <IconButton>
                      <Link to={`/dashboard/orders/${value?.id}`}>
                        <VisibilityOutlinedIcon />
                      </Link>
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        ),
      },
    },
  ];
}
