import { useTranslation } from "react-i18next";
import Cards from "../../components/Home/Cards";
import Charts from "../../components/Home/Charts";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="pageHeader">
        <h2 className="pageTitle">
          {" "}
          <DashboardOutlinedIcon />
          {t("dashboard.pageTitle")}
        </h2>
      </div>
      <Cards />
      {/* <Charts /> */}
    </div>
  );
};

export default Home;
