import "./index.scss";

const SignInGlobal = ({ Fields, pageTitle }) => {
  return (
    <div className="">
      <Fields pageTitle={pageTitle} />
    </div>
  );
};

export default SignInGlobal;
