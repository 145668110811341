import { useTranslation } from "react-i18next";

export default function PaymentTransColumns() {
  const { t } = useTranslation();
  return [
    {
      name: "item.card_number",
      label: t("common.cardNumber"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.card_name",
      label: t("common.cardName"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.state",
      label: t("common.status"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.type",
      label: t("common.cardType"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item",
      label: t("common.amount"),
      options: {
        customBodyRender: (value) => (
          <div>
            {value?.amount} {value?.currency}
          </div>
        ),
      },
    },
    {
      name: "item.created_at",
      label: t("common.time"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: "item.payment_response.TrackId",
      label: t("common.trackingId"),
      options: {
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
  ];
}
