import { createSlice } from "@reduxjs/toolkit";

import { getVendors, getVendorsWithFilter } from "../actions/vendors";

import {
  getDateWithSpecFormat,
  handleShowError,
} from "../../functions/handling";

import dayjs from "dayjs";

const initialState = {
  data: null,
  from: "2020-01-01",
  to: getDateWithSpecFormat(dayjs()),
  isLoading: false,
  error: null,
};

const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    setFrom: (state, { payload }) => {
      state.from = getDateWithSpecFormat(payload);
    },
    setTo: (state, { payload }) => {
      state.to = getDateWithSpecFormat(payload);
    },
  },
  extraReducers: {
    [getVendors.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getVendors.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getVendors.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getVendorsWithFilter.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getVendorsWithFilter.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getVendorsWithFilter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },
  },
});

export const { setFrom, setTo } = vendorsSlice.actions;

export default vendorsSlice.reducer;
