import { useSelector } from "react-redux";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import CommissionColumns from "../components/Commissions/CommissionColumns";

import { getRiders, getRidersWithFilter } from "../store/actions/riders";

import { setFrom, setTo } from "../store/slices/riders";

export default function RidersCommissions() {
  const { isLoading, data } = useSelector((state) => state?.riders);

  return {
    path: "riders-commissions",
    pageName: "riders",
    apiCall: getRiders,
    apiCallWithFilter: getRidersWithFilter,
    isLoading,
    IconPath: CurrencyExchangeIcon,
    data,
    tableColumns: CommissionColumns,
    setFrom,
    setTo,
  };
}
