import { createSlice } from "@reduxjs/toolkit";
import { getDashboardData } from "../actions/dashboard";

const initialState = {
  data: null,
  range: "",
  isLoading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    [getDashboardData.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getDashboardData.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    [getDashboardData.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default dashboardSlice.reducer;
