import { useTranslation } from "react-i18next";

import VerifyFields from "../../components/SignIn/VerifyFields";

export default function VerifyRoute() {
  const { t } = useTranslation();

  return {
    path: "verify-email",
    pageName: "verify-email",
    pageTitle: t("common.verifyEmail"),

    Fields: VerifyFields,
  };
}
