import { useTranslation } from "react-i18next";

import ResetPasswordFields from "../../components/SignIn/ResetPasswordFields";

export default function ResetPasswordRoute() {
  const { t } = useTranslation();

  return {
    path: "reset-password",
    pageName: "reset-password",
    pageTitle: t("common.resetPassword"),

    Fields: ResetPasswordFields,
  };
}
