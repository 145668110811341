import axios from "axios";

const ApiConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

ApiConfig.interceptors.request.use((req) => {
  let lang = localStorage.getItem("lang") || sessionStorage.getItem("lang");
  req.headers.lang = lang;
  if (localStorage.getItem("tokenForResetPassword")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem(
      "tokenForResetPassword"
    )}`;
  }
  if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
    req.headers.Authorization = `Bearer ${
      localStorage.getItem("token") || sessionStorage.getItem("token")
    }`;
  }
  return req;
});

export default ApiConfig;
