import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../images/Logo.svg";

import { ReactComponent as Logo } from "../../images/inv_qr.svg";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { login } from "../../store/actions/auth";

import { setRemember } from "../../store/slices/auth";
import { Link } from "react-router-dom";

export default function SignInFields({ pageTitle }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  return (
    <>
      {/* <p className="svg-logo">
        <Logo />
      </p>
      <h3>{pageTitle}</h3> */}
      {/* <div className="forall-logo text-center">
        <Logo />
      </div> */}

      <form onSubmit={handleSubmit} method="post">
        <FormControl fullWidth className="formControl">
          <label>{t("common.email")}</label>
          <OutlinedInput
            placeholder={t("common.email")}
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormControl>
        <FormControl fullWidth className="formControl">
          <label>{t("common.password")}</label>
          <OutlinedInput
            placeholder={t("common.password")}
            id="outlined-adornment-password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Link to="/forgot-password">{t("common.forgotPassword")}</Link>
        <div className="rememberMe">
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                onClick={(e) => dispatch(setRemember(e.target.checked))}
              />
            }
            label={t("common.rememberMe")}
          />
        </div>
        <button type="submit" disabled={isLoading} className="signin-button">
          {isLoading ? <CircularProgress /> : t("common.signIn")}
        </button>
      </form>
    </>
  );
}
