import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { updatePassword } from "../../../../store/actions/auth";

import "./index.scss";

export default function ChangePasswordModal({
  open,
  handleClose,
  isLoadingForAction,
  Fields,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const methods = useForm();
  const onSubmit = methods.handleSubmit(async (data) => {
    const res = await dispatch(updatePassword(data));
    if (!res?.error) {
      methods.reset();
      handleClose();
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="changePasswordDialog"
    >
      <DialogTitle
        sx={{ display: "flex", alignItems: "center", gap: "4px" }}
        id="alert-dialog-title"
      >
        <EditIcon /> {t("common.update")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("common.updatePassword")}
        </DialogContentText>
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            noValidate
            autoComplete="off"
            id="updatePasswordForm"
            style={{ marginTop: "30px" }}
          >
            <Fields />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ gap: "16px" }}>
        <button
          form={"updatePasswordForm"}
          type="submit"
          disabled={isLoadingForAction}
          autoFocus
        >
          {t("common.update")}
        </button>
        <button onClick={handleClose}>{t("common.cancel")}</button>
      </DialogActions>
    </Dialog>
  );
}
