import { createSlice } from "@reduxjs/toolkit";

import {
  getVendorsSubscriptions,
  getVendorsSubscriptionsWithFilter,
} from "../actions/vendorsSubscriptions";

import {
  getDateWithSpecFormat,
  handleShowError,
} from "../../functions/handling";

import dayjs from "dayjs";

const initialState = {
  data: null,
  from: "2020-01-01",
  to: getDateWithSpecFormat(dayjs()),
  isLoading: false,
  error: null,
};

const vendorsSubscriptionsSlice = createSlice({
  name: "vendorsSubscriptions",
  initialState,
  reducers: {
    setFrom: (state, { payload }) => {
      state.from = getDateWithSpecFormat(payload);
    },
    setTo: (state, { payload }) => {
      state.to = getDateWithSpecFormat(payload);
    },
  },
  extraReducers: {
    [getVendorsSubscriptions.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getVendorsSubscriptions.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getVendorsSubscriptions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },

    [getVendorsSubscriptionsWithFilter.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getVendorsSubscriptionsWithFilter.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload?.data;
    },
    [getVendorsSubscriptionsWithFilter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      handleShowError(state?.error);
    },
  },
});

export const { setFrom, setTo } = vendorsSubscriptionsSlice.actions;

export default vendorsSubscriptionsSlice.reducer;
