import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiConfig from "../../services/apiConfig";

import { handleError } from "../../functions/handling";
import { useNavigate } from "react-router-dom";

const requestUri = `/operation/orders?type=order&state=completed`;

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (navigate, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(requestUri);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/");

      if (error.response.status >= 400 && error.response.status <= 409) {
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getOrdersWithFilter = createAsyncThunk(
  "orders/getOrdersWithFilter",
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        orders: { from, to },
      } = getState();

      const res = await ApiConfig.get(`${requestUri}&from=${from}&to=${to}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);

export const getOrder = createAsyncThunk(
  "orders/getOrder",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await ApiConfig.get(`/operation/orders?type=order&id=${id}`);
      if (res?.data?.success) {
        return res.data;
      } else {
        return rejectWithValue(res?.data?.message);
      }
    } catch (error) {
      if (error.response.status >= 400 && error.response.status <= 409) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
      }
      const finalError = handleError(error);
      return rejectWithValue(finalError);
    }
  }
);
