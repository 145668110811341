import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Input } from "../../components/global/Form/Input";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  name,
  email,
  mobile,
  country_code,
} from "../../utils/inputValidations/editprofile";
import { SelectInput } from "../../components/global/Form/Select";
import "./FieldsProfile.scss";

import "./FieldsProfile.scss";
import { getCountries } from "../../store/actions/profile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const [disableValidation, setDisableValidation] = useState(false);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FieldForEditProfile({ data }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const methods = useFormContext();
  const { watch } = useFormContext();
  let { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let [countryCode, setCountryCode] = useState("");
  const { isLoading, countriesdata } = useSelector((state) => state?.profile);

  let splitCountryCode = `+${data?.mobile?.split(")")[0]?.slice(1)}`;
  let splitMobile = `${data?.mobile?.split(")")[1]}`;

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  let optionsCountriesCodes = [];
  if (countriesdata != null) {
    countriesdata.forEach((ele) => {
      let option = {
        label: `${ele?.mobile_code}`,
        value: `${ele?.mobile_code}`,
      };
      optionsCountriesCodes.push(option);
    });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label={t("common.fields")} {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Input {...name} value={data?.name} />
        <Input {...email} value={data?.email} />
        <label>{t("common.phone")}</label>
        <div className="d-flex align-items-center gap-2  w-100 ">
          <div className="selectCountryCode w-25">
            <SelectInput
              DontShowplaceholder={true}
              customWidth="50px"
              {...country_code}
              options={optionsCountriesCodes}
              value={splitCountryCode && splitCountryCode}
            />
          </div>
          <Input {...mobile} value={splitMobile} showIcon={true} />
        </div>
      </TabPanel>
    </Box>
  );
}
