import { toast } from "react-toastify";

export function handleError(error) {
  console.log("error => ", error);
  if (error?.response && error?.response?.data) {
    if (error?.response?.data.errors) {
      return error?.response?.data?.errors;
    }
    return error?.response?.data?.message;
  } else if (error?.message) {
    return error?.message;
  } else {
    return error;
  }
}

export function handleShowError(error) {
  if (typeof error === "object" && Object?.keys(error)?.length !== 0) {
    Object?.keys(error)?.map((item) => toast.error(error[item][0]));
  } else {
    toast.error(error);
  }
}

export function getDateWithSpecFormat(date) {
  return `${date?.$y}${date?.$M === 10 || date?.$M === 11 ? "-" : "-0"}${
    date?.$M + 1
  }${date?.$D < 10 ? "-0" : "-"}${date?.$D}`;
}
