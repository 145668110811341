import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./slices/auth";
import dashboardSlice from "./slices/dashboard";
import profileSlice from "./slices/profile";
import deliveringSlice from "./slices/delivering";
import ordersSlice from "./slices/orders";
import tripsSlice from "./slices/trips";
import vendorsSubscriptionsSlice from "./slices/vendorsSubscriptions";
import salesSlice from "./slices/sales";
import vendorsSlice from "./slices/vendors";
import ridersSlice from "./slices/riders";
import usersSlice from "./slices/users";

const store = configureStore({
  reducer: {
    auth: authSlice,
    dashboard: dashboardSlice,
    profile: profileSlice,
    orders: ordersSlice,
    delivering: deliveringSlice,
    trips: tripsSlice,
    vendorsSubscriptions: vendorsSubscriptionsSlice,
    sales: salesSlice,
    vendors: vendorsSlice,
    riders: ridersSlice,
    users: usersSlice,
  },
});

export default store;
